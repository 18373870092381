.termsContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    height: calc(100vh - 40px);
    overflow-y: auto;
    position: relative;
    margin-top: 20px;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
    touch-action: manipulation;
}

.termsContainer::-webkit-scrollbar {
    width: 8px;
}

.termsContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.termsContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.termsContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.termsContainer {
    -ms-overflow-style: none;
    scrollbar-width: thin;
}

.title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    color: #600AAD;
    top: 0;
    background: #f9f9f9;
    padding: 10px 0;
    z-index: 1;
}

.effectiveDate {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    color: #777;
}

.section {
    margin-bottom: 20px;
}

.heading {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #222;
}

.subheading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #555;
}

.text {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
}

.list {
    list-style: disc;
    margin-left: 20px;
    margin-bottom: 10px;
    color: #555;
}

.list li {
    margin-bottom: 5px;
}

.contact {
    font-size: 16px;
}

.contact strong {
    color: #444;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .termsContainer {
        margin-top: 0;
        height: 100vh;
        padding: 16px;
        border-radius: 0;
        font-size: 16px;
    }

    .title {
        font-size: 24px;
        padding: 8px 0;
    }

    .effectiveDate {
        font-size: 14px;
    }

    .heading {
        font-size: 20px;
    }

    .subheading {
        font-size: 16px;
    }

    .text {
        font-size: 14px;
        line-height: 1.5;
    }

    .list {
        font-size: 14px;
        padding-left: 16px;
    }

    .list li {
        padding: 4px 0;
    }

    input, 
    button, 
    select, 
    textarea {
        font-size: 16px !important;
    }
}

/* Prevent text selection during touch interactions */
@media (hover: none) {
    .termsContainer {
        user-select: none;
        -webkit-user-select: none;
    }
}
  