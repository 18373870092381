.container {
  width: 100%;
  padding: 20px;
  background-color: white;
  height: calc(100vh - 60px); /* Adjust based on your header/navbar height */
  overflow-y: auto;
 
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  /* position: sticky; */
  top: 0;
  background-color: white;
  z-index: 10;
  padding: 10px 0;
}

.tabs {
  display: flex;
  gap: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  padding: 5px;
}

.tab {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  border-radius: 40px;
  transition: all 0.3s ease;
}

.activeTab {
  background: #600AAD;
  color: white;
}

.searchBar {
  width: 300px;
}

.searchBar input {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 14px;
}

.categoryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 20px 0;
}

.deletePortfolio {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  z-index: 3;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  padding: 4px;
  border-radius: 4px;
}

.categoryCard {
  aspect-ratio: 1/1;
  border: none;
  border-radius: 4px;
  background: black;

  padding: 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  /* background: rgba(96, 10, 173, 0.3); */
}

.categoryCard:first-child , .categoryCard:nth-child(2) {
  background: white;
  border: 1px dashed #600AAD;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.plusIcon {
  font-size: 32px;
  color: #600AAD;
  margin-bottom: 4px;
}

.categoryImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.5;
}

.categoryContent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  z-index: 4;
} 
.categoryContent h3 {
  margin: 0;
  font-size: 12px;
  color: black;
    z-index: 4;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.status {
  position: absolute;
  bottom: 8px;
  z-index: 3;
  right: 1rem;
  white-space: nowrap;
}

/* Status button styles */
.applyButton {
  background: #FFB800;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 10px;
  font-weight: normal;
}

.appliedButton {
  background: #089B00;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 10px;
  font-weight: normal;
}

.rejectedButton {
  background: #FF0000;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 10px;
  font-weight: normal;
}

.categoryCard:first-child p , .categoryCard:nth-child(2) p {
  margin: 0;
  color: #600AAD;
  font-size: 12px;
}

.statusButton {
  border: none;
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 500;
  cursor: default;
}
.videoIcon{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  /* top: 50%; */
  bottom:35%;
  padding: 8px;
  /* z-index: 4; */
  color: white;
}
.videoIcon p{
  color: white;
  /* white-space: nowrap; */
  overflow: hidden;
  /* text-overflow: ellipsis; */
  max-width: 100%;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
    height: calc(100vh - 56px); /* Adjust for mobile header height */
    top: 56px; /* Adjust for mobile header height */
    padding-bottom: 3rem;
    margin-bottom: 3rem;
  }

  .header {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }

  .searchBar {
    width: 100%;
    margin: 0;
  }

  .tabs {
    width: 100%;
  }

  .tab {
    width: 100%;
    white-space: nowrap;
  }

  .categoryGrid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 12px;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 60px; /* Add padding at bottom for better scroll experience */
    margin-bottom: 3rem;
  }
}