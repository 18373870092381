/* Modal overlay background */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modalContent {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

/* Close button */
.modalClose {
  /* position: absolute; */
  /* top: 5px;
  right: 10px; */
  /* background: none; */
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.head{
  padding: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align:center;
  width: 100%;
  height: 3rem;

}
.profile{
  display: flex;
  align-items: center;
  font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
gap: 10px;
color:#666666;

}
.profile img{
  height: 3rem;
  width: 3rem;
  border-radius:50%;

}
.box{
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: .5rem;
 
}

.left{
  width: 40%;
  color: #152536;
  font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: left;

}

.right{
  font-family: Poppins;
font-size: 12px;
font-weight: 600;
line-height: 24px;
text-align: left;

}
.name{
  color: #600AAD;
}
.editDelete{
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
margin-top: 1rem;
}
.edit{
background: #600AAD;
color: white;
width: 6rem;
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid #600AAD;
border-radius: 50px;
padding: .5rem;
  
}

.delete{
  color: #600AAD;
  display: flex;
  justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid #600AAD;
border-radius: 50px;
padding: .5rem;
}
.info{
  margin-bottom: 1rem;
}
  /* Responsive styling for mobile */
  @media (max-width: 767px) {
    .modalContent {
      width: 90%;
      max-width: 90%;
      padding: 1rem;
    }
  }
  