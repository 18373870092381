/* Category container styles */
.inventoryDetailContainer{
    width: 70rem;
    height: calc(100vh - 70px);
    padding: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Enable vertical scrolling */
}

/* Hide scrollbar for all major browsers */
.inventoryDetailContainer {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.inventoryDetailContainer::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.mainEdit {
    gap: 10px;
    width: 100%;
    height: calc(100vh - 2rem);
    display: flex;
    overflow-y: auto;
    background: white;
    border-radius: 45px;
}

.leftEdit {
    width: 40%;
    margin: auto;
    gap: 10px;
}

.leftEdit input {
    width: 50%;
    margin-left: 8rem;
}

.leftEdit img {
    width: 15rem;
    margin: auto;
}

.rightEdit {
    width: 55%;
    margin: auto;
    height: 100%;
    overflow-y: auto;
}

.cardEdit {
    border-radius: 8px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.headEdit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.headEdit p {
    font-size: 24px;
    font-weight: 600;
    color: #600AAD;
}

.saveCancel {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 10px;
}

.edit{
    background: #600AAD;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 45%;
    border: 2px solid #600AAD;
    border-radius: 50px;
    padding: 0.5rem;
}
.delete{
    color: #600AAD;
    background: white;
    display: flex;
    width: 45%;

    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid #600AAD;
    border-radius: 50px;
    padding: 0.5rem;
}  


.infoEdit {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.description {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.description textarea {
    border: 2px solid #600AAD;
    width: 100%;
    margin-top: 0.5rem;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    color: #999999;
}

.item,
.availUnit,
.editPrice,
.category {
    display: flex;
    justify-content: left;
    width: 100%;
    margin-top: 1.5rem;
    gap: 5px;
}

.availUnit {
    width: 100%;
}
.available{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.item input {
    background: #DEE2E6;
}

input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    /* text-align: center; */
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

input {
    padding: 0.5rem; /* Set consistent padding */
    margin: 0; /* Remove default margin */
    border: 1px solid #ccc; /* Define border explicitly */
    box-sizing: border-box; /* Include padding in width */
    border-radius: 4px; /* Optional: Define your own border-radius */
    -webkit-appearance: none; /* Remove Safari's native styles */
    appearance: none; /* Reset for modern browsers */
  }

  
label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
}



.category {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
    margin-top: 0.5rem;
}

.category select {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 16px;
    
    border: 1px solid rgb(159, 155, 155);
}

.editGodown {
    margin-top: 0.5rem;
    border: 1px solid rgb(209, 203, 203);
}
.selectGodown{
    border: 1px solid #600AAD;
    margin-top: 1rem;
    border-radius: 45px;
    color: white;
    background: #600AAD;
    padding: .5rem;
    /* width: 100%; */
}
.select{
    border: 1px solid rgb(205, 197, 197);
display: flex;
gap: 10px;
justify-content: space-around;
padding: .5rem;
margin-top: 1rem;
}
.save{
    /* width: 40%; */
    background: #600AAD;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #600AAD;
    border-radius: 42px;
    padding: .5rem;
}
.input{
    display: flex;
align-items: center;
text-align: center;
justify-content: center;

    /* margin-top: .5rem; */
}
.go select {
    padding: 0.5rem;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 16px;
    border: 1px solid rgb(159, 155, 155);
}
.go button{
    color: #600AAD;
display: flex;
align-items:start;
justify-content: start;
    margin-left: 0;

}
.items{
display: flex;
    justify-content: space-between;
    width: 52%;
    margin-top: 1rem;
    margin-bottom: -1rem;
}
.add{
    display: flex;
justify-content:right;
align-items: center;
text-align: center;
padding: 0%;
width: 100%;
gap: 10px ;
}
.addAfter{
    display: flex;
    flex-direction: column;
    justify-content:right;
    align-items: center;
    text-align: center;
    padding: 0%;
    width: 100%;
    gap: 10px ;
}
.godown{
    /* width: 48%; */
}
.godown button{
    border-radius: 45px;

}
.addGodown button{
    border: 1px solid #600AAD;
    margin-top: 1rem;
    border-radius: 45px;
    color: white;
    background: #600AAD;
     padding: .5rem;
    }
    .addGodown{
    /* width: 48%; */
    white-space: nowrap;

}
/* .price{
    margin-top: 1rem;
    border: 1px solid black;
} */
.addCat{
    border: 1px solid #600AAD;
    margin-top: 1rem;
    border-radius: 45px;
    background: #600AAD;
    padding: auto;
    width: 10rem;
    height: 2rem;
    align-items: center;
    margin: auto;
}
.category button{
    color: white;
    margin-left: 0%;
}
.unit{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: center;
}   
.unit select{
    width: 100%;
    border-radius: 10px;
    padding: .5rem;
}
.item label{
    white-space: nowrap;
}
/* Media Queries */
@media (max-width: 767px) {
    .inventoryDetailContainer {
        margin-right: 0;
        padding: 0rem;
        height: 72vh;
    }

    .mainEdit {
        flex-direction: column;
        /* padding: 0rem;
        margin: 0%; */
        align-items: center;
        height: 100vh;
    }

    .rightEdit {
        width: 100%;
        margin: 0;
        padding: 0%;
        text-align: center;
        max-height: 100vh;
    }

    .leftEdit {
        display: none;
    }

    .cardEdit {
        margin: 1rem;
        padding: .5rem;
        box-shadow: none;
    }
    .items{
        margin-bottom: 0;
        width: 75%;
    }
.item img{
    height: 3rem;
    width: 3rem;
}
    .headEdit {
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
    }

    .headEdit p {
        font-size: 25px;
        margin-top: -1rem;
        width: 100%;
    }

    .saveCancel {
        width: 100%;
        justify-content: space-evenly;
        margin: 0;
        gap: 5px;
    }

    .save {
        /* padding: 0.5rem 1rem; */
        width:9rem;
    }
.add{
    justify-content: space-around;
}
.addGodown button{
    padding: .8rem;
}
    .infoEdit {
        width: 100%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .item,
    .availUnit,
    .editPrice,
    .categoryGodown {
        width: 100%;
        margin-top: 0rem;
        justify-content: center;
        gap: 5px;
    }

    .description {
        margin-top: 0.5rem;
    }

    .editPrice div {
        width: 33%;
        align-items: center;
    }


    .infoEdit > div {
        margin-bottom: 1rem;
    }
    .select {
        flex-direction: column; /* Stack items vertically */
        gap: 10px;
        padding: 0.5rem;
        width: 100%; /* Full width on mobile */
        justify-content: center;
    }

    .godown {
        width: 100%; /* Full width for mobile */
        padding: 0.5rem;
        text-align: center; /* Center the text for neatness */
        white-space: nowrap;
        align-items: center;
    }

    .editPrice {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        padding: 1rem 0; /* Adjust padding */
    }

    .go {
        width: 100%;
        display: flex;
        flex-direction: column; /* Stack select and button */
        gap: 10px;
        margin-top: 0.5rem;
        justify-content: center;
    }

    .go select {
        width: 100%; /* Ensure select input takes full width */
        padding: 0.5rem;
        font-size: 16px;
        border-radius: 4px;
    }

    .go button {
        width: 100%;
        justify-content: center; /* Center button on mobile */
    }

    .selectGodown {
        width: 100%;
        margin-top: 1rem;
        padding: 0.75rem; /* Adjust padding */
        border-radius: 20px; 
        text-align: center;
    }
}

.saveCancel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 1rem 0;
}

.save, .cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s ease;
}

.save {
    background-color: #600AAD;
    color: white;
}

.save:hover {
    background-color: #45007d;
}

.cancel {
    background-color: #ffffff;
    color: #600AAD;
    border: 2px solid #600AAD;
}

.cancel:hover {
    background-color: #f0e6ff;
}

/* Media Queries */
@media (max-width: 992px) {
    .saveCancel {
        gap: 15px;
        flex-wrap: wrap; 
    }

    .save, .cancel {
        padding: 0.6rem 1.2rem;
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .saveCancel {
        gap: 10px;
    }

    .save, .cancel {
        width: 100%; 
        padding: 0.5rem 1rem;
        font-size: 14px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input {
      padding: 0.5rem !important; /* Explicit padding for Safari */
    }
  }