/* Category container styles */
.container {
  width: 100%;
  height: 100vh;
  padding: 1rem;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Hide scrollbar for all major browsers */
.container {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.partyTopTab {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0.5rem;
  border-radius: 45px;
  position: sticky;
  top: 0;
  gap: 10px;
  margin-top: 1rem;
}

.back {
  display: flex;
  align-items: center;
  background: #600aad;
  color: white;
  border-radius: 50px;
  padding: 0.5rem;
  gap: 5px;
  border: none;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
}

.backtext {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.24px;
  text-align: left;
  color: #666666;
}

.CardContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  overflow-y: auto;
  height: auto;
  position: relative;
  /* margin-bottom: 2rem; */
  padding-bottom: 4rem;
}

.info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0.5rem;
}

.box1 {
  margin-top: 0;
}

.box {
  /* margin-top: 1rem; */
}



.text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #600aad;
}

label {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: black;
}

input {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #878787;
}

.saveCancel {
  display: flex;
  margin-left: auto;
  justify-content: right;
  align-items: center;
  gap: 10px;
  /* margin-top: 2rem; */
}

.save {
  background: #600aad;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 2px solid #600aad;
  border-radius: 50px;
  padding: 0.5rem;
  cursor: pointer;
}

.save.disabled {
  background: #cccccc;
  border-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.top{
  display: none;
}
.cancel {
  color: #600aad;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 2px solid #600aad;
  border-radius: 50px;
  padding: 0.5rem;
}
.imageDiv{
  display: flex;
  justify-content: space-between;
  gap: 50px;

}
.image{
  width: 5rem;
  border: 1px solid black;
}
/* Media Queries */

/* Tablets (768px and up) */
@media (max-width: 1024px) {
  .container {
    margin-right: 1rem;
    padding: 0.5rem;
  }

  .partyTopTab {
    justify-content: center;
    gap: 5px;
  }

  .top p{
    color: #600aad;

    font-size: 30px;
    font-weight: 600;
  }
  .back {
    font-size: 14px;
    padding: 0.4rem;
  }

  .CardContainer {
    gap: 10px;
    padding: 1rem;
    margin-bottom: 1rem;

  }
      .saveCancel {
        gap: 15px;
        flex-wrap: wrap; /* Allow buttons to wrap if needed */
    }

    .save, .cancel {
        padding: 0.6rem 1.2rem;
        font-size: 14px;
    }
}


/* Mobile Devices (max-width: 768px) */
@media (max-width: 768px) {
  .container {
    margin-right: 0;
    padding: 0.25rem;
    height: 100vh;
    overflow-y: auto;
    position: relative;
    padding-bottom: 100px;
  }

  .CardContainer {
    margin-top: 0rem;
    height: auto;
    min-height: auto;
    padding: 15px;
    gap: 15px;
    margin-bottom: 80px;
    overflow-y: visible;
    background-color: white;
    max-height: none;
  }

  .top {
    display: block !important;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #600aad;
    margin-bottom: 15px;
    padding-top: 10px;
  }

  .partyTopTab {
    display: none;
  }

  .saveCancel {
    /* position: fixed; */
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 15px;
    margin: 0;
    margin-top: 0%;
    margin-bottom: 1rem;
    z-index: 100;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
  }

  .save, .cancel {
    min-width: 120px;
    padding: 10px 20px;
    font-size: 14px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
    position: relative;
    z-index: 101;
  }

  .save:active, .cancel:active {
    opacity: 0.8;
  }

  .box {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
  }

  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }

  .one, .two {
    width: 100%;
  }

  .one input, .two input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .save, .cancel {
    min-width: 120px;
    padding: 10px 20px;
    font-size: 14px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .save.disabled {
    background: #cccccc;
    border-color: #cccccc;
    cursor: not-allowed;
    opacity: 0.7;
  }

  .CardContainer {
    /* margin-bottom: 100px; */
    padding-bottom: 80px;
  }

  .save:not(.disabled), .cancel {
    pointer-events: auto;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {
  .container {
    padding-bottom: 120px;
  }
  
  .CardContainer {
    /* margin-bottom: 100px; */
  }
}
