:root {
  --btn-color: #600aad;
}

* {
  margin: 0%;
  padding: 0%;
}

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding-left: 20px;
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
}
.bottom{

  background: #0000001A;
} 
@media  (max-width: 767px){
  .bottom{
    background-color: white;

  }
}

.sidebar {
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  width: 17%;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
}

.sidebar.hidden {
  transform: translateX(-100%);
}

.sidebar.visible {
  transform: translateX(0);
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}
.signup-link{
  color: #600aad;
  font-weight: 600; 
  cursor: pointer;
}

@media (min-width: 1200px) {
  #main,
  #footer {
    margin-left: 300px;
  }
}


@media (max-width: 768px) {
  .sidebar {
    /* width: 70%; */
    display: none;
  }
  /* .dash,
  .order,
  .party,
  .inventory {
    margin-right: 2rem;
  } */
}



@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #4154f1;
  transition: 0.3;
  background: #f6f9ff;
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: #4154f1;
}

.sidebar-nav .nav-link.collapsed {
  color: #012970;
  background: #fff;
}

.sidebar-nav .nav-link.collapsed i {
  color: #899bbd;
}

.sidebar-nav .nav-link:hover {
  color: #4154f1;
  background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
  color: #4154f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}


.party {
  margin-right: 9rem;
}

.dashboard {
  margin: 0%;
  margin-right: 6rem;
}
.order {
  margin-right: 8.6rem;
}
.inventory{
  margin-right: 6.5rem;
}


  /* Main container styles */
  .main-container {
    width: 100%;
    min-height: calc(100vh - 60px);
    overflow-x: hidden;
    display: flex;
    /* flex-direction: column; */
    transition: all 0.3s ease;
    padding: 1rem;
    box-sizing: border-box;
    margin-top: 60px;
  }
  .main-container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;    /* Firefox */
  }
  
  .main-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media (min-width: 769px) {
    .main-container {
      width: calc(100% - 17%);
      margin-left: 17%;
    }
  }

  @media (max-width: 768px) {
    .main-container {
      width: 100%;
      margin-left: 0;
      padding: 0.5rem;
    }
  }