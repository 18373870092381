.container {
  width: 100%;
  height: 100vh;
  margin: 0rem !important;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box; 
}

/* Hide scrollbar for all major browsers */
.container , .cardContainer{
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
  
  .topTab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  
  .back {
    display: flex;
    align-items: center;
    background: #600aad;
    color: white;
    border-radius: 50px;
    padding: 0.5rem;
    gap: 5px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
  }
  
  .topTab p {
    color: #666666;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    margin-top: 0.2rem;
  }
  
  .cardContainer {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    flex-direction: column;
    border-radius: 8px;
    padding: 2rem;
    margin-top: .5rem;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    max-height: 40rem;
    position: relative;
  }
  
  .head {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .head p {
    color: #600aad;
    font-weight: 400;
    font-size: 20px;
  }
  
  .info {
    margin-top: 1rem;
    width: 80%;
    display: flex;
    justify-content: start;
    gap: 15px;
  }
  .date{
    display: flex;
    gap: 10px;
    width: 80%;
  }
  
  label {
    color: black;
    font-size: 16px;
    font-weight: 500;
  }
  .select{
    padding: 1rem;
    width:100%;
    border: 1px solid #600aad;
    border-radius: 45px;
margin-top: 1rem;
  }
  .select h1{
    text-align: center;
  }
 /* Ensure table container has a fixed height for scrolling */
.table {
  width: 100%;
  height: 15rem; /* Adjust this height as needed */
  overflow-y: auto;
  border-collapse: collapse;
  /* padding: 1rem; */
  margin-bottom: 1rem;
  position: relative; 
}

.table {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.table::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.table thead {
  position: sticky;

  top: 0; 
  background-color: white; 
}

/* Table header cells */
.table th {
  background-color: white;
  color: #600aad;
  white-space: nowrap;
  padding: 10px;
  border-bottom: 3px solid #600aad;
}

/* Table body cells */
.table td {
  text-align: start;
  align-items: start;
  padding: .5rem;
  border: none; 
  background: rgb(249, 246, 246);
}

/* Optional: Add a hover effect to the rows */
.table tbody tr:hover {
  background-color: #f1f1f1; /* Light gray background on hover */
}

  
  .additem {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 1rem;
  }
  
  .additem button {
    color: white;
    background: #600aad;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    border-radius: 50px;
  }
  .subTotal{
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: .5rem;
  }
  .subTotal p{
    font-size: 20px;
    gap:20px;
    color: black;
  }
  .paymentInfo {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap; 
  }
  .gst{
    display: flex;
    align-items: center;
    gap: 1rem;
  }
 
  .paymentInfo div {
    flex: 1 1 20%; 
    width: 25%;
    gap: 10px;
  }
  
  .saveCancel {
    display: flex;
    margin-left: auto;
    justify-content: right;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .save {
    background: #600aad;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid #600aad;
    border-radius: 50px;
    padding: 0.5rem;
  }
  
  .cancel {
    color: #600aad;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 5rem;
    border: 2px solid #600aad;
    border-radius: 50px;
    padding: 0.5rem;
  }
 
  /* Media Query for Tablets (768px and below) */
  @media (max-width: 768px) {
    .container {
      margin-right: 0;
      padding: 0.25rem;
      height: 87vh;
      /* margin-bottom: 4rem; */
    }
  
    .cardContainer{
      margin-right: 0;
      width: 100%;
      padding: 0;
      margin-bottom: 4rem;
     
    }
    .gst{
      gap: 0;
      margin: 0;
    }
    .paymentInfo{

gap: 10px;
    }
    
    .topTab {
     display: none;
    }
    .head{
        margin-top: 0;
    }
  
    .back {
      font-size: 14px;
      padding: 0.4rem;
    }
  
    .topTab p {
      font-size: 18px;
    }
  
    .cardContainer {
      padding: 1rem;
   
    }
  
    .info {
      flex-direction: column;
      width: 100%;
    }
  
  
    .saveCancel {
    }
    .table{
      width: 100%;
      height: auto;
      padding: 0;
      margin: 0;
      /* border: 1px solid red; */
    }
    .select{
      padding: 0;
      border: none;
      width: 100%;
    }
  }
 
  
  /* Media Query for Mobile (480px and below) */
  @media (max-width: 480px) {
   
   
  
    
    .head{
        margin-top: 0;
        align-items: center;
    }
  
    .head p{
font-size: 26px;
align-items: center;
text-align: center;

    }
    .date{
        width: 100%;
        margin: auto;
        gap: 10px;
        display: flex;
        justify-content: center;
    }

    .paymentInfo {
        flex-wrap: wrap; 
        gap: 10px; 
        width: 100%;

      }
    
      .paymentInfo div {
        flex: 1 1 45%; 
      }
      
    .info {
      gap: 10px;
    }
  
    .table {
      width: 100%;
      height: auto;
    }
  
    .saveCancel {
      gap: 50px;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 3rem;
      /* padding: 2rem; */
      position: relative;
    }
    .save ,.cancel{
      width: 50%;
    }
  }
  @media (max-width: 1200px) {
    .container {
      margin-right: 2rem; /* Adjust margin for smaller screens */
    }
  }

  
 