

  /* Category container styles */
  .inventoryDetail-container{
    width: 70rem;
    height: calc(100vh - 70px);
    padding: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  /* Hide scrollbar for all major browsers */
  .inventoryDetail-container , .right {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  .inventoryDetail-container::-webkit-scrollbar , .right-detail::webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .inventory-detail-top-button p {
    color: #666666;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    margin-top: 0.2rem;
  }
  .inventory-detail-top-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-left: 1rem;
  }
  
  
  /* Inventory Top Tab */
  .inventory-top-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    width: 100%;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    padding: 0.5rem;
    margin: auto;
    width: 95%;
   
    border-radius: 45px;
    position: sticky;
    margin-top: 1rem;
    top: 0;
  }

  .back {
    display: flex;
    align-items: center;
    background: #600aad;
    color: white;
    border-radius: 50px;
    padding: 0.5rem;
    gap: 5px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
  }
  
.main{
    gap: 10px;
    width: 100%;
    margin-top: 3rem;
    height: 100vh;
    display: flex;
    overflow-y: auto;
}

.left{
width: 40%;
margin: auto

}
.left img{
width: 15rem;
margin: auto;
}
.left{
  /* margin-top: 10rem; */
  height: 90vh;
  margin-top: auto;
 
}
.right{
    width: 60%;
    
}
.card{
    /* margin: 2rem; */
  
   border-radius: 8px;
   box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
   padding: 1rem;
}
.head{
    display: flex;
    justify-content: space-between;
align-items: center;
text-align: center;
}
.head p{
    font-size: 24px;
    font-weight: 600;
    color: #600AAD;

}
.edit-delete{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

}
.edit{
background: #600AAD;
color: white;
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid #600AAD;
border-radius: 50px;
padding: .5rem;
    
}

.mobile{
    display: none;
}
.delete{
    color: #600AAD;
    display: flex;
    justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid #600AAD;
border-radius: 50px;
padding: .5rem;
}
.info{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 30px;
    margin-top: .5rem;
}
.price{
    color: grey;
    font-size: 20px
}
.price span{
    color: #600AAD;
}
.discount{
    border: 1px solid #5BBD5B;
     background: #5BBD5B ;
     text-align: center;
     color: white;
     border-radius: 20px;
     Padding:  .2rem;
     width: 4rem;
}
.text{
    margin-top: 1rem;
    font-family: Poppins;
font-size: 20px;
font-weight: 300;
line-height: 30px;
text-align: left;

}
.item-detail{
   width: 100%;
 display: flex;flex-direction: column;
   
}

.inventory-items{
  width: 100%;
  display: flex;
      justify-content: flex-start;


  }
  .inventory-items-title{
    font-size: 18px;
    justify-content: flex-start;
    display: flex;
    width: 30%;
    font-weight: 500;
    color: #600AAD;
  } 
  .inventory-items-value{
    font-size: 18px;
    font-weight: 400;
    color: #152536;
    display: flex;
    justify-content: flex-start;
    width: 70%;
  }


.btn-custom {
  background: #600aad;
  color: white;
  border-radius: 20px;
}
.phoneedit-delete{
  display: none;
}


/* Small screens (mobile devices) */
@media (max-width: 767px) {
  .inventoryDetail-container{
    margin-right: 0;
    padding: 0.25rem;
    height: 100vh;
    overflow-y: auto;
}
    .main {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-top: 2rem;
      /* height: 100%;  */
      /* max-height: 40rem; */
      overflow-y: auto; /* Enable vertical scrolling */
      margin: 0%;
      margin-bottom: 4rem;
    }
    .web{
        display: none;
    }
    .head p{
      display: none;
    }
  .mobile{
    display: block;
    width: 100%;
  }
  .phoneedit-delete{
    display: flex;
    width: 100%;
    gap: 10px;
    margin-bottom: 2rem;
  }
    .left, .right {
      width: 100%;
      margin: 0;
     
      text-align: center;
    }
  
    .left img {
      width: 100%;
      margin-bottom: 1rem;
      margin:0;
    }
 
    .card {
      margin: 1rem;
      padding: 1rem;
      border: none;
      box-shadow: 0px 0px white;
    }
  
    .head {
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }
  
    .head p {
      font-size: 20px;
      font-weight: 600;
    }
  
    .edit-delete {
      /* gap: 15px; */
      /* justify-content: center; */

    }
    .item-detail{
      gap: 10px;
    }
    .inventory-items-title{
      width: 50%;
    } 
    .inventory-items-value{
      width: 40%;
      margin-left: 1rem;
      white-space: nowrap;
    }
  

    .edit, .delete {
      padding: 0.5rem 1rem;
      font-size: 14px;
      width: 10rem;
    }
  
    .info {
      gap: 10px;
      align-items: center;
      text-align: center;
    }
 
    .price {
      font-size: 22px;
      margin-top: 1rem;
    }
  
    .discount {
      width: auto;
      padding: 0.2rem 1rem;
    }   
  .left{
    height: 20rem;
  }
    .text {
      margin-top: -2rem;
      text-align: justify;
    }
  
    .item-detail {
      align-items: center;
      text-align: center;
      gap: 10px;
      margin-top: 1rem;
      
    }
  
    .left-detail, .right-detail {
      text-align: center;
    }
  
    .left-detail p{
        font-size: 16px;
        line-height: 25px;
        padding: .3rem;
      }
    
      .left img{
        width: 80%;
        margin: auto;
        height: 10rem;
      }
    .right-detail p {
      font-size: 16px;
      line-height: 25px;
    }
    .inventory-detail-top-button{
        display: none;
    }
    .inventoryDetail-container{
      background: white;
    }
  }
  