.container {
    width: 100%;
    height: calc(100vh - 60px);
    margin: 0;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    border-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    
}

/* Hide scrollbar for all major browsers */
.container , .formContainer{
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;    /* Firefox */
}

.container::-webkit-scrollbar  , .cardContainer::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.formContainer {
  width: 100%;
  padding: 1rem;
  padding-bottom:2rem;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #600AAD;
  color: white;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.infoIcon {
  font-size: 1.5rem;
}

.section {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.section h3{
    font-size: 30px;
    font-weight: 500;
    margin-bottom: .5rem ;
    color: #600AAD;
}
.inputGroup {
  margin-bottom: 1.5rem;
}
.selectbox{
    width: 9rem;
    border: 3px solid rgb(18, 146, 61);
}
.inputGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
}

.inputGroup input,
.inputGroup textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.mediaNote {
  color: #666;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.imageUpload {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.uploadButton {
  position: relative;
  width: 100px;
  height: 100px;
  border: 2px dashed #ddd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}

.uploadButton input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.uploadButton span {
  color: #666;
  font-size: 14px;
  text-align: center;
  pointer-events: none;
}

/* Add hover effect */
.uploadButton:hover {
  border-color: #600AAD;
}

.uploadButton:hover span {
  color: #600AAD;
}

.previewImage {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 1rem;
}

.previewImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.productDetails {
  margin-top: 2rem;
}

.detailsTable {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  width: 60%;
  padding: 1rem;
}

.detailRow {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.detailTitle,
.detailValue {
  width: 50%;
}

.detailTitle input,
.detailValue input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.addRowButton {
  background: #600AAD !important;
  width: 4rem;
  color: white;
  padding: .3rem;
  border-radius: 45px;
  margin-top: 1rem;
  margin-bottom: .5rem;
  margin-left: .5rem;
  cursor: pointer;
  border: none;
}

.addRowButton:hover {
  background: #4c0887 !important;
}

.buttonGroup {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
}

.addButton,
.createButton {
  padding: 0.75rem 2rem;
  border-radius: 25px;
  font-weight: 500;
  cursor: pointer;
}

.addButton {
  background-color: white;
  border: 2px solid #600AAD;
  color: #600AAD;
  margin-bottom: 1rem;

}

.createButton {
  background-color: #600AAD;
  border: none;
  color: white;
  margin-bottom: 1rem;
}
.input{
    display: flex;
    gap: 10px;
    width: 100%;
    /* justify-content: space-between; */
    width: 100%;
    /* padding: 0.75rem; */
    border-radius: 4px;
    font-size: 1rem;
    margin-bottom: 1rem;
}
.input div{
    width: 48%;
}
.tabletitle{
    width: 60%;
    display: flex;
    justify-content: space-around;
}
.tabletitle p{
    color: #333;

}
.detailsTable button{
    background: blue;
    width: 4rem;
    color: white;
    padding: .3rem;
    border-radius:45px;
    margin-top: 1rem;
    margin-bottom: .5rem;
    margin-left: .5rem;
    justify-content: end;
}

.remove {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ff4d4f;
  color: #ff4d4f;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1;
}

.remove:hover {
  background: #ff4d4f;
  color: white;
}

/* Mobile Styles */
@media (max-width: 768px) {
   
    
    .container{
            max-height: 34rem; /* Set a height to make it scrollable */
            padding: 0rem;
            gap: 10px;
            overflow-y: auto; /* Enable vertical scrolling */
            margin-bottom: 2rem;
          
        }
        .container {
          margin-right: 0;
          margin-top: 0%;
          padding: 0rem;   
        }
  .formContainer {
    /* padding: 0.5rem; */
    /* padding-bottom: 100px; */
  }

  .section {
    padding: 1rem;
    margin-bottom: 0.5rem;
  }

  .buttonGroup {
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 0.75rem;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    margin: 0;
    z-index: 1000;
  }

  .addButton,
  .createButton {
    width: 50%;
    padding: 0.75rem 0;
    text-align: center;
  }

  .imageUpload {
    gap: 0.5rem;
  }

  .uploadButton,
  .previewImage {
    width: calc(50% - 0.25rem);
    height: 100px;
  }

  .detailsTable {
    width: 100%;
  }

  .detailRow {
    flex-direction: column;
    gap: 0.5rem;
  }

  .detailTitle,
  .detailValue {
    width: 100%;
  }
}

/* Smaller screens */
@media (max-width: 480px) {
  .formContainer {
    /* padding-bottom: 90px; */
  }

  .section {
    margin-bottom: 0.5rem;
  }

  .buttonGroup {
    padding: 0.5rem;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .container {
    max-height: 34rem;
    padding: 0rem;
    gap: 10px;
    overflow-y: auto;
    margin-bottom: 2rem;
    margin-right: 0;
    margin-top: 0%;
  }

  .formContainer {
    padding: 0.5rem;
  }

  .section {
    padding: 1rem;
    margin-bottom: 0.5rem;
  }

  .input {
    flex-direction: column;
    gap: 1rem;
  }

  .input div {
    width: 100%;
  }

  .imageUpload {
    gap: 0.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 columns */
    width: 100%;
  }

  .uploadButton,
  .previewImage {
    width: 100%; /* Takes full width of its grid cell */
    height: 120px;
    margin: 0;
  }

  .detailsTable {
    width: 100%;
  }

  .tabletitle {
    width: 100%;
  }

  .buttonGroup {
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 0.75rem;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    margin: 0;
    z-index: 1000;
  }
}

/* Smaller screens */
@media (max-width: 480px) {
  .imageUpload {
    gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr); /* Maintains 2 columns */
  }

  .uploadButton,
  .previewImage {
    height: 100px;
  }
}
