/* Inventory Detail Container */
.inventoryDetail-container {
  width: 100vw;
  height: 100vh;
  /* padding: 1rem; */
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Inventory Detail Top Button */
.inventory-detail-top-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-left: 1rem;
}

.back {
  display: flex;
  align-items: center;
  background: #600aad;
  color: white;
  border-radius: 50px;
  padding: 0.5rem;
  gap: 5px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
}

.inventory-detail-top-button p {
  color: #666666;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  margin-top: 0.2rem;
}

/* Inventory Top Tab */
.inventory-top-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 100%;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  padding: 0.5rem;
  width: 100%;
  border-radius: 45px;
  position: sticky;
  margin-top: 1rem;
  top: 0;
}
.search input {
  border-radius: 40px;
  width: 20rem;
}
/* Inventory Buttons */
.inventory-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.additem,
.catalog {
  border: 2px solid #600aad;
  padding: 0.5rem;
  border-radius: 50px;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #600aad;
  display: flex;
  justify-content: center;
  align-items: center;
}

.catalog {
  margin-left: 1rem;
}

.card-img-top {
}
.inventory-card-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 2rem;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 2rem;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  overflow-y: auto;
  height: 25rem;
  max-height: none;
  position: relative;
}

.inventory-card-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.inventory-card-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.btn-custom {
  background: #600aad;
  color: white;
  border-radius: 20px;
}

.inventory-card-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* Additional margin at the bottom of the container */
.inventory-card-container::after {
  content: "";
  display: block;
  height: 1rem; /* Adjust height as needed */
}

/* Box Styles */
.box {
  background-color: hsl(0, 0%, 100%);
  padding: 15px; /* Decreased padding for smaller size */
  border: 1px solid #600aad;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 8px;
  display: flex;
  color: rgb(61, 94, 155);
  transition: transform 0.3s;
  flex: 1 1 calc(25% - 20px); /* Decrease the percentage for smaller boxes */
  max-width: calc(25% - 20px); /* Ensure the boxes don’t exceed the row width */
  box-sizing: border-box; /* Make sure padding doesn’t affect the width */
  margin-bottom: 20px; /* Space between rows */
}

.box img {
  width: 45%;
  height: 10rem;
  object-fit: cover;
}
.card-img-top {
  width: 20%;
}

.btn-view-more {
  align-self: flex-end;
  color: #600aad;
}
.card-detail {
  margin-left: 1rem;
}
.card-detail p {
  font-family: Poppins;
  color: #15253699;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.card-detail span {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: black;
}
.card-title {
  font-family: Poppins;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: #600aad;
}

.btn-view-more {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  border-bottom: 3px solid #600aad;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
  border-radius: 0%;
}
/* Media Queries */

/* Large screens (desktops) */
@media (min-width: 1200px) {
  .inventory-card-container {
    height: auto; /* Adjust for larger screens */
  }
  .box {
    flex: 1 1 calc(33.33% - 20px); /* 3 cards per row */
  }
}

/* Medium screens (tablets) */
@media (max-width: 1199px) and (min-width: 768px) {
  .inventory-top-tab {
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0;
  }
  .inventory-btn {
    margin-top: 1rem;
  }
  .box {
    flex: 1 1 calc(50% - 20px); /* 2 cards per row */
  }
}

/* Small screens (mobile devices) */
@media (max-width: 767px) {
  .main-container {
    width: 100%;
    margin-left: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .inventory-btn {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
  .inventory-btn button{
    width: 48%;

  }
  .inventory-top-tab {
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0%;
    margin: 0;
    width: 100%;
  }
  .inventory-detail-top-button {
    display: none;
  }

  .inventory-btn {
    margin-top: 1rem;
    width: 100%;
  }

  .additem,
  .catalog {
    margin: auto;
  }
  .search {
    width: 100%;
    margin-left: 0;
  }

  .search input {
    width: 100%;
    margin: 0;
  }

  .search button {
    width: auto;
    margin-left: 0.5rem;
  }

  .inventory-btn {
    margin-top: 1rem;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }
  .box {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .text-custom {
    width: 10rem;
  }
}
