.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modalContent {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

/* Close button */
.modalClose {
  /* position: absolute; */
  /* top: 5px;
  right: 10px; */
  /* background: none; */
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
  .profile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  gap: 10px;
  
}
.profile p{
      color: #600AAD;

  }
  .availUnit {
    display: flex;
    justify-content: left;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5rem;
    gap: 5px;
}
.saveCancel{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 1rem;

}
.save{
background: #600AAD;
color: white;
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid #600AAD;
border-radius: 50px;
padding: .5rem;
width: 6rem;
    
}
    /* Responsive styling for mobile */
    @media (max-width: 767px) {
      .modalContent {
        width: 90%;
        max-width: 90%;
        padding: 1rem;
      }
    }
    

