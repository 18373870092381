.container {
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  height: calc(100vh - 60px); /* Fixed height based on viewport */
  max-height: calc(100vh - 60px); /* Maximum height */
  overflow-y: auto !important; /* Force vertical scrolling */
  position: relative; /* Establish positioning context */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}
.container::-webkit-scrollbar {
  display: none;
}

/* Tab Navigation */
.tabContainer {
  display: flex;
  margin-bottom: 20px;
  background: white;
  border-radius: 30px;
  padding: 5px;
  width: 60%;
  margin: 0 auto 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  border: 2px solid #600AAD;
  /* position: sticky;
  top: 0;
  z-index: 10; */
}

.tabButton {
  padding: 10px 25px;
  border: none;
  background: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  color: #666;
  transition: all 0.3s ease;
  width: 50%;
  white-space: nowrap;
}

.activeTab {
  background: #600AAD;
  color: white;
  width: 50%;
}

/* Tender Grid */
.tenderGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding-bottom: 50px; /* Add padding at the bottom for better scrolling experience */
}

/* Tender Card */
.tenderCard {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 2px solid #600AAD;
}

.tenderCard:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.cardHeader {
  padding: 15px;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #600AAD;
}

.eventImage {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.cardBody {
  padding: 15px;
}

.eventName {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.location {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #666;
  font-size: 14px;
}

.locationIcon {
  margin-right: 5px;
  color: #600AAD;
}

.dateRange {
  color: #666;
  font-size: 14px;
}

.cardFooter {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f0f0f0;
}

/* Status Badges */
.statusBadge {
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
}

.upcomingStatus {
  background-color: #FFF8E1;
  color: #FFB800;
}

.runningStatus {
  background-color: #E8F5E9;
  color: #4CAF50;
}

.closedStatus {
  background-color: #FFEBEE;
  color: #F44336;
}

.viewDetailsButton {
  background: none;
  /* border: none; */
  color: #600AAD;
  font-size: 12px;
  cursor: pointer;
  /* text-decoration: underline; */
  border: 1px solid #600AAD;
  border-radius: 40px;
  padding: 5px 10px;
  font-weight: 500;

}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    height: calc(100vh - 56px); /* Adjust for mobile header height */
    max-height: calc(100vh - 56px);
    padding: 15px;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch;
  }
  
  .tabContainer {
    width: 100%;
    justify-content: center;
    /* position: sticky; */
    /* top: 0; */
    /* z-index: 10; */
  }
  
  .tabButton {
    padding:1rem;
    font-size: 14px;
    flex: 1;
    text-align: center;
    white-space: nowrap;
  }
  
  .tenderGrid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    padding-bottom: 70px; /* More padding on mobile */
  }
}

@media (max-width: 480px) {
  .container {
    padding: 10px;
    height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
  }
  
  .tenderGrid {
    grid-template-columns: 1fr;
    gap: 15px;
    padding-bottom: 100px; /* Even more padding on small screens */
  }
  
  .cardFooter {
    /* flex-direction: column; */
    gap: 10px;
    align-items: flex-start;
  }
  
  .viewDetailsButton {
    align-self: flex-end;
  }
}
