.container {
  width: 100%;
  height: 100vh;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  
}

/* Hide scrollbar for all major browsers */
.container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.topTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-left: 1rem;
  margin-top: 1rem;
}

.back {
  display: flex;
  align-items: center;
  background: #600aad;
  color: white;
  border-radius: 50px;
  padding: 0.5rem;
  gap: 5px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
}

  .topTab p {
    color: #666666;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    margin-top: 0.2rem;
  }
  
  .cardContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 2rem;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    overflow-y: auto;
    height: auto;
    max-height: 42rem;
    position: relative;
    margin-bottom: 2rem;
    padding-bottom: 4rem;

  }
  
  .cardContainer {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }
  
  .cardContainer::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  .head {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .head p {
    color: #600aad;
    font-weight: 400;
    font-size: 20px;
  }
  
  .info {
    margin-top: 1rem;
    width: 80%;
    display: flex;
    justify-content: start;
    gap: 15px;
  }
  .date{
    display: flex;
    gap: 10px;
    width: 80%;
  }
  
  label {
    color: black;
    font-size: 16px;
    font-weight: 500;
  }

  .table {
    width: 100%;
    height: auto; 
    max-height: 13rem;
    overflow-y: auto;
    border-collapse: collapse;
    /* padding: 1rem; */
    margin-bottom: 1rem;
    position: relative; 
  }
  
  .table, .returnTable{
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  .table::-webkit-scrollbar,.returnTable::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
  
  .returnTable{
    width: 100%;
    height: auto;
    max-height: 10rem; /* Adjust this height as needed */
    overflow-y: auto;
    border-collapse: collapse;
    /* padding: 1rem; */
    margin-bottom: 1rem;
    position: relative; 
  }
  .table thead {
    position: sticky;
    top: 0; 
    background-color: white; 
  }
  
  /* Table header cells */
  .table th {
    background-color: white;
    color: #600aad;
    padding: 10px;
    border-bottom: 3px solid #600aad;
  }
  
  /* Table body cells */
  .table td {
    text-align: start;
    align-items: start;
    padding: .5rem;
    border: none; 
    background: rgb(249, 246, 246);
  }
  
  /* Optional: Add a hover effect to the rows */
  .table tbody tr:hover {
    background-color: #f1f1f1; /* Light gray background on hover */
  }
  
  .paymentInfo{
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    margin-left: auto;
    gap: 50px;
  }
  .row, .row1{
    display: flex;
    justify-content: space-between;
    gap: 100px;
}
.row1{
  margin-top: .2rem;
}

  .row p{
    color: black;
    font-size: 18px;
}
  .saveCancel {
    width: 100%;
    gap: 10px;
    margin-top: 1rem;
    display: flex;
    justify-content: end;
    align-items:center;
    flex-wrap: wrap;
    border-radius: 8px;
    padding: 1rem;
    margin-top: .5rem;
    background-color: white;
  }
  
  .save {
    background: #600aad;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid #600aad;
    border-radius: 50px;
    padding: 0.5rem;
    width: 8rem;
  }
  
  .cancel {
    color: #600aad;
    gap: 10px;
    width: 7rem;
    border: 2px solid #600aad;
    border-radius: 50px;
    padding: 0.5rem;
    white-space: nowrap;
  }
  
  .cancels{
    color: #600aad;
    gap: 10px;
    width: 9rem;
    border: 2px solid #600aad;
    border-radius: 50px;
    padding: 0.5rem;
  }
  .extraDetail{
border: 1px solid rgb(218, 208, 208);
width: 100%;
margin-top: 1rem;
border-radius: 16px;
padding: .5rem;
display: flex;
justify-content: right;
gap: 20px;
}
  .extraDetail button{
    background: #600aad;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid #600aad;
    border-radius: 50px;
    padding: 0.3rem;
    width: 8rem;
  }
  .selectedItem {
    border: 1px solid rgb(218, 208, 208);
width: 100%;
margin-top: 1rem;
border-radius: 16px;
padding: .5rem;
display: flex;
justify-content:center;
gap: 20px;
  }
  .selectedItem p{
    color: #600aad;
    font-size: 20px;
  }
  .additem {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: .5rem;
  }
  
  .additem button {
    color: white;
    background: #600aad;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    border-radius: 50px;
  }
  
  
  .date input{
    cursor: pointer;
  }
  .field-group,
.date-picker {
  margin-bottom: 20px; /* Adds space between fields and the calendar */
}


.edit input{
  display: flex;
  flex-direction: column;
border: 2px solid #600aad !important; 
}
.confirmButton{
  background: #600aad;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 2px solid #600aad;
  border-radius: 50px;
  padding: 0.5rem;
}
.overlayLoader {  /* Changed to camelCase for CSS modules */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
  @media (min-width: 1024px) {
    .topTab p {
      font-size: 20px;
    }
  
    .info, .date {
      gap: 15px;
    }
  
    .paymentInfo {
      flex-direction: row;
      gap: 50px;
    }
  
    .row, .row1 {
      gap: 100px;
    }
  }
  
  /* Tablet Screens */
  @media (max-width: 1024px) {
    .container {
      margin-right: 2rem;
    }
  
    .cardContainer {
      padding: 1.5rem;
    }
  
    .topTab p {
      font-size: 18px;
    }
  
    .info {
      flex-direction: column;
      width: 100%;
    }
  
    .paymentInfo {
      flex-direction: row;
      gap: 30px;
    }
  
    .table {
      height: auto;
    }
  }
  
  /* Mobile Screens */
  @media (max-width: 768px) {
    .container {
      margin-right: 0;
      padding: 0.25rem;
      height: 100vh;
    }
  .cardContainer{
    margin-bottom: 0rem;
    padding: 0.5rem;
    gap: 10px;
    margin-top: 0%;
    overflow-y: auto;
  }
    .topTab {
      /* flex-direction: column; */
      align-items: flex-start;
    }
  
    .back {
      font-size: 14px;
      padding: 0.3rem;
      gap: 3px;
    }
  
    .topTab p {
      font-size: 16px;
    }
  
    .cardContainer {
      padding: 1rem;
    }
  
  .info{
    flex-direction: column;

  }
    .date{
      margin-left: 0;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      /* flex-wrap: wrap; */
      width: 100%;
      /* border: 1px solid black; */
    }
    /* .date > div {
      flex: 1;
      width: 100%;
    } */
    
    .paymentInfo {
      align-items: flex-start;
      gap: 20px;
    }
  
    .row, .row1 {
      gap: 50px;
    }
  
    .saveCancel {
      display: flex;
      /* flex-direction: row; */
      justify-content: right;
      /* align-items: stretch; */
      /* border: 1px solid black; */
      gap: 10px;
      margin-bottom: 8rem;
    }
  
    .save, .cancel {
      width: 6rem;
      padding: 0.5rem;
    }
    .cancels{
      width:8rem;
      /* padding: 0.2rem; */
    }
  
    .extraDetail, .selectedItem {
      /* flex-direction: column; */
      /* align-items: flex-start; */
      gap: 10px;
      padding: 0.1rem;
      border: none;
      margin-top: .5rem;

    }
  
    .extraDetail button {
      width: 100%;
      padding: 0px;
      
    }
  
  }

  /* Container for the entire range picker */
.range-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-aligns the range picker */
  /* width: 100%; */
  margin: 0 auto;
  padding: 20px; /* Space around the range picker */
}

/* Title styling */
.range-title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}



/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .range {
    width: 90%; /* Restrict width for smaller screens */
  
    margin-left:-16rem ;
    margin-bottom: 0%;
  }

  .range-title {
    font-size: 16px; /* Adjust title font size */
  }
  .cancel{
    padding: .5rem;
    width: 8rem;
  }
}

/* Add these styles after the .info class definition */

.info input,
.date input,
.edit input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  height: 36px;
  display: flex;
  box-sizing: border-box;
}

/* Update the existing .edit input style */
.edit input {
  border: 2px solid #600aad !important;
  height: 36px;
}

/* Add styles for the date picker input */
.edit .react-datepicker-wrapper,
.edit .react-datepicker__input-container input {
  width: 200px;
  height: 36px;
  padding: 8px 12px;
  border: 2px solid #600aad;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

@media (max-width: 576px) {
  .container {
    margin-right: 0;
    padding: 0.5rem;
  }

  .partyCardContainer {
    max-height: 25rem; /* Set a height to make it scrollable */
    padding: 0.5rem;
    gap: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    margin-bottom: 2rem;
  }}
   

  