.container {
  width: 100%;
  height: 100vh;
  padding: 1rem;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Hide scrollbar for all major browsers */
.container , .cardContainer{
-ms-overflow-style: none;  /* Internet Explorer 10+ */
scrollbar-width: none;  /* Firefox */
}

.container::-webkit-scrollbar ,.cardContainer::-webkit-scrollbar { 
display: none;  /* Safari and Chrome */
}

.header {
text-align: center;
margin-bottom: 0;
/* margin-bottom: 2rem; */

width: 100%;
top: 0;
padding: 1rem 0;
z-index: 10;
}

.header h1 {
color: #600aad;
font-size: 2rem;
font-weight: 600;
}

.cardContainer{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  /* gap: 20px; */
  /* margin-top: 2rem; */
  border-radius: 8px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  overflow-y: auto;
  height: auto;
  /* max-height: 37rem; */
  position: relative;
  margin-bottom: 2rem;
  padding-bottom: 4rem;
}
.plansContainer {
display: flex;
justify-content: center;
gap: 2rem;
flex-wrap: wrap;
max-width: 1200px;
margin: 0 auto;
padding-bottom: 4rem;
}

.planCard {
  background-color: white;
  border-radius: 16px;
  padding: 2rem;
  width: 400px;
  margin-top: 1rem;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);

  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease;
  height: fit-content;
  position: relative;
}

.planCard:hover {
transform: translateY(-10px);
}

.silver {
border: 2px solid #C0C0C0;
background: linear-gradient(90deg, #E8E8E8 0%, #C0C0C0 50%, #B8B8B8 100%);
color: #333;



}

.gold {
border: 2px solid #FFD700;
background: linear-gradient(90deg, #FEF3B8 0%, #EFBF04 50%, #EFBF04 100%);
color: #333;
}

.planHeader {
text-align: center;

/* margin-bottom: 1.5rem; */
}

.planIcon {
font-size: 2rem;
margin-top: .5rem;
}

.planType {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  margin-bottom: 0.5rem;
}

.planPrice {
  font-size: 2rem;
  font-weight: 700;
  color:white;
}

.buyButton {
width: 100%;
padding: 0.8rem;
border-radius: 25px;
border: none;
background: white;
color: silver;
font-size: 1rem;
font-weight: 600;
cursor: pointer;
margin: 1rem 0;
transition: background-color 0.3s ease;
}
.buyButton1 {
  width: 100%;
  padding: 0.8rem;
  border-radius: 25px;
  border: none;
  background: white;
  color:gold;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin: 1rem 0;
  transition: background-color 0.3s ease;
  }

.buyButton:hover {
background: #4c0887;
color: white;
}

.features {
  margin-top: 1rem;
  background: white;
  padding: 1rem;
  border-radius: 8px;
}

.feature {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: black !important;
}

.checkmark {
  color: #600aad !important;
  font-weight: bold;
}

/* Add these styles for the details alignment */
.item-detail {
  display: flex;
justify-content: flex-start;
gap: 40px;
margin-top: 1rem;
padding: 0.5rem;
}

.left-detail {
  display: flex;
flex-direction: column;
gap: 10px;
min-width: 150px;
}

.left-detail p {
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
color: #666;
color:black;

margin: 0;
}

.right-detail {
display: flex;
flex-direction: column;
gap: 10px;
}

.right-detail p {
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
color:black;
margin: 0;
}
.planSelect{
margin: auto;
margin-top: -1rem;
}
.featureButton{
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.featureButton button{
  color: #0075E8;
  font-weight: 600;
  font-size: 1rem;
}

/* Update Mobile Styles */
@media (max-width: 768px) {
  .container {
    margin-right: 0;
    padding: 0.25rem;
    height: 100vh;
  }

  .cardContainer {
    padding: 0.5rem;
    gap: 10px;
    margin-top: 0%;
    max-height:80vh;
    overflow-y: auto;
    margin-bottom: 2rem;
  }

  .header {
    margin: 0;
    padding: 0.5rem 0;
  }

  .header h1 {
    font-size: 24px;
    margin: 10px 0;
  }

  .planSelection {
    flex-direction: column;
    padding: 0;
  }

  .planSelection p {
    text-align: center;
    margin: 0;
  }

  .planSelection div {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    width: 100%;
  }

  .planSelection select {
    width: 100%;
    margin: 0;
    padding: 8px;
  }

  .plansContainer {
    padding: 0;
    gap: 1rem;
    padding-bottom: 4rem;
  }

  .planCard {
    width: 100%;
    margin: 0.5rem 0;
  }

  .planInfo {
    top: 10px;
    right: 10px;
  }

  .duration, .device {
    padding: 3px 8px;
    font-size: 0.7rem;
  }

  .planHeader {
    margin-top: 1.5rem;
  }

  .planType {
    font-size: 1.2rem;
  }

  .planPrice {
    font-size: 1.5rem;
  }

  .features {
    padding: 0.8rem;
  }

  .feature {
    font-size: 0.85rem;
  }

  .buyButton {
    padding: 0.7rem;
    font-size: 0.9rem;
  }
}

/* Additional styles for even smaller screens */
@media (max-width: 480px) {
  .container {
    padding: 0;
  }

  .cardContainer {
    padding: 0.3rem;
  }

  .header h1 {
    font-size: 30px;
  }
  .planSelection{
    margin: 0;
  }

  .planCard {
    padding: 1rem;
  }

  .planInfo {
    top: 8px;
    right: 8px;
  }

  .duration, .device {
    padding: 2px 6px;
    font-size: 0.65rem;
  }

  .planType {
    font-size: 1.1rem;
  }

  .planPrice {
    font-size: 1.3rem;
  }

  .feature {
    font-size: 0.8rem;
  }

  .buyButton {
    padding: 0.6rem;
    font-size: 0.85rem;
  }

  .plansContainer {
    padding-bottom: 6rem;
  }
}

/* Update these color-specific styles for silver plan to ensure visibility */
.silver .planType,
.silver .planPrice,
.silver .feature,
.silver .checkmark {
  color: black;
}

/* Add styles for the plan info container */
.planInfo {
  display: flex;
  gap: 8px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.duration, .device {
  background: rgba(255, 255, 255, 0.5);
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.8rem;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Add styles for the plan selection at the top */
.planSelection {
  display: flex;
  width: 100%;
  justify-content:flex-end;
  /* border: 1px solid black; */
  /* margin: -2rem; */

  align-items: center;
  /* margin-bottom: 2rem; */
  /* gap: 8rem; */
  padding: 0 1rem;
}

.planSelection p {
  color: #666;
  font-size: 0.9rem;
  margin: auto;
}

.planSelection select {
  padding: 6px 16px;
  border-radius: 20px;
  border: 1px solid #ddd;
  background: white;
  cursor: pointer;
  margin-left: 10px;
  font-size: 0.9rem;
}