.container {
    width: 100%;
    height: calc(100vh - 3.75rem);
    margin: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-top: -1rem; */
    margin-bottom: 2rem;
    padding: 2rem;
    background: white;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    margin-bottom: 2rem ;
    height: 100%;
}

.searchContainer {
    width: 100%;
}

.searchButton input {
    padding: 0.4rem;
    width: 30%;
    height: 2.5rem;
    margin-left: 2rem;
    border-radius: 0.5rem;
    border: 1px solid #e0e0e0;
    background: #F5F5F5;
}

.productDetails {
    display: flex;
    gap: 2rem;
    width: 100%;
    padding: .5rem;
}

/* Image Section */
.imageSection {
    width: 48%;
    display: flex;
    /* height:45rem; */
    flex-direction: column;
    border: 1px solid #E9E9EB;
    gap: 1rem;
    border-radius: 8px;
    height: 48rem;
    margin: 0%;
}

.imageContainer {
    width: 100%;
    height: 25rem;
    border-radius: 0.5rem;
    padding: 1rem;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.imageDetails {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    overflow-x: auto;
    height: 5rem;
    padding: 0.5rem;
}

.imageDetailsItem {
    min-width: 5rem;
    height: 5rem;
    border: 1px solid #E9E9EB;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.imageDetailsItem img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Details Section */
.detailsSection {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.detailsContainer {
    padding: 1rem;
    border: 1px solid #E9E9EB;
    border-radius: 0.5rem;
}

.name {
    font-size: 1.5rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: 1rem;
    font-family: 'Poppins', sans-serif;
}

.priceContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.discountedPrice {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000000;
}

.originalPrice {
    font-size: 1.2rem;
    text-decoration: line-through;
    color: #6B7280;
}

.discountedPercentage {
    color: #600AAD;
    font-weight: 500;
}

/* Specifications Section */
.specificationsContainer {
    /* margin-top: 2rem; */
    padding: 1rem;
    border-radius: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between; 
}
.specificationsTable{
    border: 1px solid #E9E9EB;
    border: radius 8px;;
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    height: auto;
}
.vendorDetails{
    width: 100%;
    border: 1px solid #E9E9EB;
    border-radius: 8px;
    padding: 1rem;
}
.vendorName{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.vendorHeader img{
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    object-fit: cover;
}
.vendorHeader p{
    font-size: 1.25rem;
    font-weight: 500;
    color: #000000;
}
.vendorName{
    display: flex;
    margin-top: .3rem;
}
.vendorName p{
    font-size: 1.25rem;
    font-weight: 400;
    color: #000000;
}
.viewShopButton{
    border: 1px solid #600AAD;
    color: #600AAD;
    border-radius: 0.25rem;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: end;
    margin-left: auto;
    justify-content: end;
    padding: 0.5rem 1rem;
}
.vendorProduct{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: .3rem;
}
.vendorProduct p{
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #6B7280;
}
.sectionTitle {
    font-size: 1.25rem;
    font-weight: 500;
    /* margin-bottom: 1rem; */
    color: #000000;
}
    
.specTable {
    width: 100%;
    border-collapse: collapse;
}

.specTable tr {
    margin: 0%;
    border-bottom: 1px solid #E9E9EB;
}

.specTable tr:last-child {
    border-bottom: none;
}

.specLabel {
    padding: 1rem 0;
    color: #000000;
    width: 30%;
    font-weight: 500;
    border-right: 1px solid #E9E9EB;
}

.specValue {
    color: #6B7280;
    padding: 1rem ;
    margin:0rem;
    width: 70%;
}

/* Description Section */
.descriptionContainer {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid #E9E9EB;
    border-radius: 0.5rem;
}

.descriptionText {
    color: #6B7280;
    line-height: 1.5;
}

/* Rating Box */
.ratingBox {
    margin: 1rem 0;
    padding: 1rem 0;
    border-top: 1px solid #E9E9EB;
    border-bottom: 1px solid #E9E9EB;
}

.ratingScore {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.score {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    display: flex;

}

.stars {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: #FFA41C;
}

.reviews {
    color: #6B7280;
    font-size: 0.875rem;
}

/* Delivery Section */
.deliverySection {
    padding: 1rem 0;
    border-bottom: 1px solid #E9E9EB;
}

.deliveryHeader {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    color: #000;
    font-weight: 500;
}

.deliveryIcon {
    font-size: 1.25rem;
    color: #600AAD;
}

.deliveryInfo {
    color: #6B7280;
    font-size: 0.875rem;
}

.phone {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
    background: white;
    color: #600AAD;
    border: 1px solid #600AAD;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 90%;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 500;
    /* padding: 0.5rem; */
}

.pincodeInput {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #E9E9EB;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

.checkButton {
    display: flex;
    gap: 10px;
    padding: 0.5rem;
    /* background: white;
    color: #600AAD;
    border: 1px solid #600AAD;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 90%;
    border-radius: 20px;
    margin: auto;
    font-size: 20px;
    font-weight: 500; */
}
.checkButton span{
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 1rem;

}


/* Offers Section */
.offersSection {
    padding: 1rem 0;
}

.offersSection h3 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #000;
}

.offersList {
    list-style: none;
    padding: 0;
}

.offersList li {
    color: #6B7280;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    position: relative;
}

.offersList li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #600AAD;
}

/* Return Policy Section */
.returnPolicyContainer {
    padding: 1.5rem;
    border: 1px solid #E9E9EB;
    border-radius: 0.5rem;
    margin: 1rem 0;
    border-bottom: none;
    border-top: none;
    margin-bottom: 0%;
    height: auto;
    width: 100%;
}

.policyHeader {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
}

.policyIcon {
    font-size: 1.5rem;
    color: #600AAD;
}

.policyHeader h3 {
    font-size: 1.125rem;
    font-weight: 500;
    color: #000;
}

.policyContent p {
    font-size: 1rem;
    color: #000;
    margin-bottom: 0.5rem;
}

.policyContent span {
    font-size: 0.875rem;
    color: #6B7280;
}

/* Reviews Section */
.reviewsContainer {
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin: 1rem 0;
    width: 100%;
}

.reviewsTitle {
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.reviewCount {
    color: #6B7280;
    font-size: 1rem;
}

.overallRating {
    padding: 1rem;
    background: #F9FAFB;
    border-radius: 0.5rem;
    width: 100%;
    margin-bottom: 2rem;
}

.ratingStats {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.avgRating {
    font-size: 2.5rem;
    font-weight: 600;
    color: #000;
}

.starsLarge {
        display: flex;
        gap: 0.5rem;
        color: #FFA41C;
        font-size: 1.25rem;
}
        
        .totalReviews {
            color: #6B7280;
            font-size: 0.875rem;
        }

/* Review Comments */
.reviewsList {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.reviewItem {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #E9E9EB;
}

.reviewHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    width: 100%;
}

.reviewerInfo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.userIcon {
    color: #600AAD;
}

.reviewerName {
    font-weight: 500;
    color: #000;
}

.reviewRating {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
}

.reviewDate {
    color: #6B7280;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    display: block;
}

.reviewText {
    color: #4B5563;
    font-size: 0.875rem;
    line-height: 1.5;
}

.viewAllButton {
    padding: 0.75rem 1.5rem;
    background: transparent;
    border: 1px solid #600AAD;
    color: #600AAD;
    border-radius: 0.25rem;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
    align-self: center;
    transition: all 0.3s ease;
}

.viewAllButton:hover {
    background: #600AAD;
    color: white;
}

.reviewFill{
    display:flex ;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    border: 1px solid #E9E9EB;

    }
/* Responsive Design */
@media (max-width: 48rem) {
   
.container {
    
    margin-right: 0;
    /* background-color:#eff1f4; */
    margin-top: 0%;
    padding: 0rem;   
    height: 100%;
    max-height: 37rem;
}
.cardContainer{

        max-height: 34rem; /* Set a height to make it scrollable */
        padding: 0rem;
        gap: 10px;
        overflow-y: auto; /* Enable vertical scrolling */
        margin-bottom: 2rem;
      
    }
    .searchContainer{
        padding: 1rem;
    }
    .productDetails {
        flex-direction: column;
    }

    .imageSection,
    .detailsSection {
        width: 100%;
        height: auto;

    }
.imageDetails{
}
.imageContainer {
        height: 20rem;
        padding: 1rem;
        margin: 0%;
    }
    .imageContainer img{
        margin: 0%;
        object-fit: cover;
    }

    .searchButton input {
        width: 100%;
        margin-left: 0;
    }

    .name {
        font-size: 1.25rem;
    }

    .discountedPrice {
        font-size: 1.25rem;
    }

    .originalPrice {
        font-size: 1rem;
    }

    .pincode {
        flex-direction: column;
    }
.specificationsContainer{
    width: 100%;
    padding: 0rem;  
    flex-direction: column;gap: 1rem;
}
.specificationsTable{
    width: 100%;
}
.specLabel{
    white-space: nowrap;
}
.vendorDetails{
    width: 100%;
}
    .checkButton {
        width: 100%;
    }

    .ratingScore {
        flex-wrap: wrap;
    }

    .returnPolicyContainer,
    .reviewsContainer {
        width: 100%;
        margin-top: 0rem;
    }

    .reviewHeader {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .starsLarge {
        font-size: 1rem;
    }

    .avgRating {
        font-size: 2rem;
    }

    .viewAllButton {
        width: 100%;
    }
}

/* Hide scrollbars but keep functionality */
.container,
.imageDetails {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.container::-webkit-scrollbar,
.imageDetails::-webkit-scrollbar {
    display: none;
}  

.reviewFormContainer {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  width: 49%;
}

.reviewForm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.reviewFormTitle {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
}

.ratingInput {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ratingInput label {
  font-size: 1rem;
  color: #4B5563;
}

.ratingStars {
  display: flex;
  gap: 0.5rem;
}

.star {
  font-size: 2rem;
  color: #DDD;
  cursor: pointer;
  transition: color 0.2s;
}

.star.filled {
  color: #FFA41C;
}

.star:hover {
  transform: scale(1.1);
}

.reviewTextArea {
  width: 100%;
  min-height: 120px;
  padding: 1rem;
  border: 1px solid #E9E9EB;
  border-radius: 8px;
  font-family: inherit;
  font-size: 1rem;
  resize: vertical;
}

.reviewTextArea:focus {
  outline: none;
  border-color: #600AAD;
}

.submitReview {
  background: #600AAD;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  align-self: flex-end;
}

.submitReview:hover {
  background: #4c0887;
}

/* Rating Distribution Bar */
.ratingDistribution {
  margin: 2rem 0;
  width: 49%;
  padding: .5rem;
}

.ratingBar {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.starCount {
  min-width: 60px;
  font-size: 0.875rem;
  color: #FFA41C;
  margin: auto;
  text-align: center ;
}

.progressBar {
  flex: 1;
  height: 8px;
  background: #F3F4F6;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.progressFill {
  height: 100%;
  background: #FFA41C;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.ratingCount {
  min-width: 50px;
  font-size: 0.875rem;
  color: #6B7280;
  text-align: right;
}

/* Responsive Design */
@media (max-width: 768px) {
  .reviewFormContainer {
    padding: 1rem;
  }

  .star {
    font-size: 1.5rem;
  }

  .submitReview {
    width: 100%;
    margin-top: 1rem;
  }

  .ratingBar {
    gap: 0.5rem;
  }

  .starCount {
    min-width: 50px;
    font-size: 0.75rem;
  }

  .ratingCount {
    min-width: 40px;
    font-size: 0.75rem;
  }

  .reviewHeader {
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
  }

  .reviewRating {
    align-items: flex-end;
    min-width: 120px;
  }

  .reviewDate {
    font-size: 0.75rem;
    text-align: right;
  }
  .reviewFill{
    width: 100%;
    flex-direction: column;
  }
  .ratingDistribution ,.reviewFormContainer{
    width: 100%;
  }
}  