/* General Styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; 
}

.login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    overflow: hidden;
}


.login-left-screen img {
    width: 100%;
    object-fit: cover; 
}

.login-right-screen {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form-container {
    border-radius: 8px;
    background: white;
    padding: 2rem;
    max-width: 600px; 
    width: 100%; 
    margin: auto;
    height: auto; 
    gap: 10px;
}

.login-head {
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    color: #600aad;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  
  .login-web-img {
  margin-top:rem ;
    gap: 10px;
  }
  
  .logo {
    margin: auto;
    margin-bottom: 2rem;
    width: 4rem;
    box-shadow: 0px 4px 4px 0px #00000026;

}

.welcome {
    width: 15rem;
    margin: auto;
}

.login-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
}

.login-img img {
    max-width: 100%;
    height: auto;
}

.login-img p {
    color: gray;
    text-align: center;
    margin-top: 1rem;
}

.login-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    width: 100%;
}

.login-mobile label {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #666666;
    width: 100%;
    margin-bottom: 0.5rem;
}

.login-mobile input {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    border: 2px solid #600aad;
    margin-top: 0.5rem;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.btn-otp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
}

.btn-otp button,
.btn-otp button:hover {
    padding: 16px;
    border-radius: 8px;
    background: #600aad;
    color: white;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
}

.btn-otp button:disabled {
    background: #ccc;
    cursor: not-allowed;
    border: none;
}

/* Responsive Design */

/* For screens larger than 768px */
@media (min-width: 768px) {
    .login-form-container {
        max-width: 500px; /* Smaller width for larger screens */
        padding: 2.5rem; /* Increase padding */
    }
    .login-img {
        display: none;
    }
    .login-head{
      display: none;
    }
}

/* For screens smaller than 768px (mobile) */
@media (max-width: 768px) {
    .login-left-screen {
        display: none; /* Hide the left screen on mobile view */
    }

    .login-web-img {
        display: none; /* Hide web images on mobile */
    }

    .login-right-screen {
        flex: 1; /* Make the right screen take full width */
        width: 100%;
        justify-content: center;
    }

    .login-form-container {
        max-width: 400px; /* Adjust max-width for smaller screens */
        padding: 1.5rem; /* Reduce padding */
        box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
    }

    .login-head {
        font-size: 20px;
        margin-top: 3rem;
    }

    .login-mobile label,
    .login-mobile input,
    .btn-otp button {
        font-size: 14px;
    }

    .btn-otp button {
        padding: 12px;
    }
}

/* For very small screens*/
@media (max-width: 468px) {
    .login-form-container {
        max-width: 300px; /* Adjust for very small screens */
        padding: 1rem;
        height: auto;
    }

    .login-head {
        font-size: 18px;
        margin-top: 2rem;
    }

    .login-mobile label,
    .login-mobile input,
    .btn-otp button {
        font-size: 12px;
    }

    .btn-otp button {
        padding: 10px;
    }
}
