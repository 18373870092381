/* Modal overlay background */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal content */
  .modalContent {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
  }
  
  /* Close button */
  .modalClose {
    /* position: absolute; */
    /* top: 5px;
    right: 10px; */
    /* background: none; */
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .head{
    padding: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align:center;
    width: 100%;
    color:#600AAD;
  }
  .info{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .img{
display: flex;
justify-content: space-between;
height: 3rem;
border: none;

  }
  .box{
    display: flex;
    gap: 20px;
    
  }
  .left,.right{
    width: 50%;
  }
  label{
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 20px;
text-align: left;
color: black;
  }
  input{
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
color: #878787;

  }
  .saveCancel{
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 10px;
    margin-top: 2rem;

}
.save{
background: #600AAD;
color: white;
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid #600AAD;
border-radius: 50px;
padding: .5rem;
    
}
.cancel{
  color: #600AAD;
    display: flex;
    justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid #600AAD;
border-radius: 50px;
padding: .5rem;
}


  /* Responsive styling for mobile */
  @media (max-width: 767px) {
    .modalContent {
      width: 90%;
      max-width: 90%;
      padding: 1rem;
    }
  }
  