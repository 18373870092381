.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
}

.modalBody {
  text-align: center;
}

.modalBody h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 24px;
  font-weight: 500;
}

.buttonGroup {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.button {
  padding: 8px 24px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.closeButton {
  background: #FF0000;
  color: white;
}

.confirmButton {
  background: #4CAF50;
  color: white;
}
