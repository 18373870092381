
  /* Category container styles */
  .container {
    width: 100%;
    height: 100vh;
    padding: 1rem;
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  /* Hide scrollbar for all major browsers */
  .container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  .container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
    
  /* Category top tab styles */
  .partyTopTab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    padding: 0.5rem;
    /* margin-right: 2rem;
    margin-left: 2rem; */
    border-radius: 45px;
    position: sticky;
    top: 0; /* Stick to the top on scroll */
    /* margin-top: 1rem; */
  }
  
  /* Category button styles */
  .addBtn {
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
        
  .btnCustom {
    background: #600aad;
    color: white;
    border-radius: 20px;
  }
 .addparty{
   border: 2px solid #600aad;
width: 10rem;
   border-radius: 50px;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #600aad;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Category card container styles */
.partyCardContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 2rem;
  border-radius: 8px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  overflow-y: auto;
  height: auto;
  max-height: 37rem;
  position: relative;
  margin-bottom: 2rem;
  padding-bottom: 4rem;
}

.partyCardContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.partyCardContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

  /* Box styles */
  .box {
    background-color: hsl(0, 0%, 100%);
    padding: 15px;
    border: 1px solid #600aad;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    color: rgb(61, 94, 155);
    transition: transform 0.3s;
    flex: 1 1 calc(20% - 20px); 
    max-width: calc(20% - 20px); 
    box-sizing: border-box; 
  }
  
 .cardImgTop{
  height: 5rem;
 }
 
  .textCustom {
    color: #600aad;
    border-radius: 15px;
    width: 20rem;
    margin-left: 1rem;
  }

  
  .btnView {
    display: flex;
    color:white;
    align-items: center;
    text-align: center;
    border-radius: 50px;
    width:60%;
    padding: 0.5;
    height: 2rem;
    border: 1px solid #600aad;
    background: #600aad;
    font-family: Poppins;
font-size:10px;
font-weight: 500;
line-height: 30px;
letter-spacing: -0.23999999463558197px;
text-align: left;

  }
  .infoDiv{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
 .title{
  font-family: Poppins;
  height: 1rem;
  margin-top: .5rem;
font-size: 15px;
font-weight: 500;
line-height: 30px;
text-align: center;
color: #600aad;
 }

 .email{
color: #666666;
display: flex ;
justify-content: center;
text-decoration: none;
margin-top: 1rem;
text-align: center;
align-items: center;
height: 1rem;
display: flex;
 }

 /* Responsive Styles */
@media (max-width: 1200px) {
  /* Adjust card container for large tablets */
  .partyCardContainer {
    height: 30rem;
  }
  
  .box {
    flex: 1 1 calc(25% - 20px); 
    max-width: calc(25% - 20px); 
  }
}

@media (max-width: 992px) {
  /* Adjust for medium tablets */
  .partyCardContainer {
    height: 28rem;
  }

  .box {
    flex: 1 1 calc(33.33% - 20px); 
    max-width: calc(33.33% - 20px); 
  }

  .textCustom {
    width: 15rem;
  }
}

@media (max-width: 768px) {
  /* Adjust for smaller tablets and large mobile devices */

  .container {
    margin-right: 0;
    padding: 0.25rem;
    height: 100vh;
    /* margin-bottom: 4rem */

  }

  .partyCardContainer {
    padding: 0.5rem;
    gap: 10px;
    margin-top: 0%;
    overflow-y: auto; /* Enable vertical scrolling */
  }
  .partyTopTab {
    flex-direction: column;
    margin-top: 0;
    border-radius: 0;
    align-items: stretch;
  }

 

  .box {
    flex: 1 1 calc(50% - 15px); 
    max-width: calc(50% - 15px); 
  }

  .addBtn {
    margin-right: 0;
    margin-top: 1rem;
  }

  .textCustom {
    width: 12rem;
    margin-left: 0;
  }

  .btnView {
    width: 100%;
    font-size: 12px;
    line-height: 20px;
  }

  .title {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .container {
    margin-right: 0;
    padding: 0.5rem;
  }

  .partyCardContainer {
    max-height: 25rem; /* Set a height to make it scrollable */
    padding: 0.5rem;
    gap: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    margin-bottom: 2rem;
  }
  .box {
    flex: 1 1 calc(50% - 15px); 
    max-width: calc(50% - 15px); 
  }

  .textCustom {
    width: 100%;
    margin: 0;
  }

  .partyTopTab {
    flex-direction: column;
    gap: 10px;
  }

  .btnView {
    width:4rem;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    align-items: center;
  }

  .title {
    font-size: 12px;
 
  }
  .addparty{
    padding: 0%;
    width: 100%;
  }
}
