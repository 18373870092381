/* Default Styles for Larger Screens */
.container {
  width: 100%;
  height: 100vh;
  margin-right: 3rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Hide scrollbar for all major browsers */
.container , .cardContainer , .range{
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.container::-webkit-scrollbar ,.cardContainer::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.topTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-left: 1rem;
  margin-top: 1rem;
}

.back {
  display: flex;
  align-items: center;
  background: #600aad;
  color: white;
  border-radius: 50px;
  padding: 0.5rem;
  gap: 5px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
}

.topTab p {
  color: #666666;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  margin-top: 0.2rem;
}

.cardContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  /* flex-direction: column; */
  gap: 20px;
  margin-top: 2rem;
  border-radius: 8px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  overflow-y: auto;
  height: auto;
  max-height: 100vh;
  position: relative;
  margin-bottom: 5rem;
  padding-bottom: 4rem;
  transition: max-height 0.3s ease-in-out; /* Smooth transition */
    /* border: 1px solid #600aad; */
}

.cardContainer.expanded {
  max-height: 100rem; /* Adjust this value as needed */
}

.head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.head p {
  color: #600aad;
  font-weight: 500;
  font-size: 30px;
  margin-top: -1rem;

}



label {
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.additem {
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 1rem;
}

.additem button {
  color: white;
  background: #600aad;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
  border-radius: 50px;
  width: 10rem;
}

.startDate{
  border: 3px solid #600aad;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.endDate{
  border: 3px solid #600aad;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center
}
.dateBox{
  width: 100%;
  display: flex;
  gap: 1rem;
  /* border: 1px solid #600aad;   */
}
.date{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* border: 1px solid #77571b; */
  gap: 20px;
  width: 90%;
}
.date p{
color:black;
}
.date button{
  border-radius: 10px;
  width: 100%;
  margin-top: -1rem;
  height: 3rem;
  /* border: 2px solid #600aad; */
}

.party{
  height: auto;
  /* z-index: 1000; */
  width:90%;
  margin-top: 2rem;
}





.range{
  width: 100%;
}

.expanded{
  height: 700px;
  margin:0;
}
.range :global(.rdrMonthAndYearPickers select){
  padding-bottom: 0;
}
.done button{
  background: #600aad;
  color: white;
  padding: .5rem;
  border-radius: 45px;
}
/* Media Queries for small screens */
@media only screen and (max-width: 600px) {
.container {
  margin: 0;
  padding: 0.25rem;
  height: 87vh;
  overflow-y: auto;
}
.cardContainer{
  margin-top: 1rem;
  height: auto;
  max-height: none;
  overflow-y: visible;
}
.party{
position: relative;
margin-top: 0;
}

.dateBox {
width: 100%; 
margin-top: 1rem;
}
.date{
margin-left: 0;
width: 100%;

}
.range {
  position: relative;
  width: 100%;
  margin-top: 0.5rem;
  overflow-x: hidden;
}

.additem button {
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.range :global(.rdrCalendarWrapper) {
  max-width: 100%;
  font-size: 10px;
}
}

/* Default Styles */
.dateBox {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  position: relative;
}


.date {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 45%;
}

.date p {
  color: black;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.date button {
  border-radius: 10px;
  width: 100%;
  height: 3rem;
  border: 2px solid #600aad;
  background-color: white;
  color: #600aad;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.date button:hover {
  background-color: #600aad;
  color: white;
}

.range {
  /* position: absolute;  */
  /* top: 100%;  */
  left: 0;
  width: fit-content;
  background: white; 
  border: 1px solid #ddd; 
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* z-index: 1000; */
  margin-top: 1rem; 
  padding: 1rem;
  margin-bottom: 2rem;
  /* height: 23rem; */
 
}

/* Media Queries */
@media only screen and (max-width: 600px) {
  .dateBox {
    gap: 0.5rem;
  }

  .date {
    width: 100%;
  }

  .range {
    /* position: absolute; */
    /* top: 100%; */
    width:fit-content;
    /* margin-right: -20rem; */
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin-bottom: 2rem;
    overflow-y: auto;           
    /* top: 0rem;  */
    font-size: 3px;
    /* margin-left: -2rem; */
  }
   .rdrDefinedRangesWrapper {
    /* width: 0; */
    border: 1px solid red;
  }
  .range :global(.rdrCalendarWrapper){
    font-size: 10px;
  }
  .range :global(.rdrWeekDay){
    font-size: 10px;
  }
  .range{
    margin-bottom: 0%;
  }
  .additem{
    margin-top: 0%;
  }
}





/* Add this more specific selector to override the default styles */
.range :global(.rdrDefinedRangesWrapper) {
  width: 0 !important;
  display: none !important;
  border: none !important;
}

