
.otp-main-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow: hidden;
}

.otp-left-screen {
  width: 40%;
}

.otp-left-screen img {
  width: 100%;
  object-fit: cover;
}

.otp-right-screen {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
.otp-web-img{
  margin-top: -rem;
}

.logo{
  margin: auto;
    margin-bottom: 2rem;
    width: 4rem;
    box-shadow: 0px 4px 4px 0px #00000026;
}

.welcome {
  width: 14rem;
  display: flex;
    margin: auto;
}

.otp-form-container {
  /* box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2); */
  border-radius: 8px;
  background: white;
  padding: 2rem;
  max-width: 600px;
  margin: auto;
  height: auto;
  gap: 10px;
}

.otp-head {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: #600aad;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.otp-head-box {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: #600aad;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.go-login {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.go-login img {
  margin-bottom: .5rem;
  border: none;
  cursor: pointer;
}

.otp-head-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #666666;
  margin: 0%;
}

.otp-head-number {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #666666;
}

.otp-container {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 25rem;
  margin: auto;
  margin: 1rem 0;
}

.otp-box {
  border: 2px solid #600aad;
  width: 4rem;
  height: 4rem;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px #0000001A;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: xx-large;
  color: black;
  text-align: center;
}

.otp-time {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  height: 2rem;
  margin: 1rem 0;
}

.otp-edit-btn {
  border: none;
  background: white;
  font-family: Poppins;
  font-size: 15px;
  color: #600aad;
  padding: 0%;
  cursor: pointer;
}
.policy{
  font-family: Poppins;
  font-size: 15px;
  color: #600aad;
  padding: 0%;
  cursor: pointer;
}
.policy-text{
  margin-left: 1rem;
  margin-top: 1rem;

}
.otp-timer span {
  width: 2rem;
}

.otp-timer {
  font-family: Poppins;
  font-size: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  /* margin-top: 1rem; */
}

.otp-text {
  
  padding: 1rem;
}

.otp-agree-text {
  margin: 0%;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.otp-term-condition {
  margin: 0%;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #600aad;
}

.otp-timer button:disabled {
  color: #ccc;
  cursor: not-allowed;
  border: none;
}
/* For screens larger than 768px */
@media (min-width: 768px) {
  .go-login {
    display: none;
  }
  .otp-text{
    display: none;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .otp-left-screen {
    display: none; /* Hide the left screen on mobile view */
  }
.otp-web-img{
  display: none;
}
  .otp-right-screen {
    width: 100%; /* Make the right screen take full width */
  }
.policy-text{
display: none;
}
  .otp-form-container {
    width: 90%; /* Adjust form width */
    max-width: none; /* Remove max-width constraint */
    box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
    
  }

  .go-login {
    margin-top: -1rem;
  }

  .otp-box {
    width: 4rem;
    height: 4rem;
    font-size: large;
    margin-bottom: 0.5rem;
  }
.otp-container{
  width:300px;
}
  .otp-time {
    height: auto;
    margin-top: 1rem;
  }

  .otp-edit-btn,
  .otp-timer {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .otp-box {
    width: 4rem;
    height: 4rem;
    font-size: medium;
  }

  .go-login {
    margin-top: -2rem;
  }

  .otp-head-text,
  .otp-head-number,
  .otp-agree-text,
  .otp-term-condition {
    font-size: 14px;
  }

  .otp-edit-btn,
  .otp-timer {
    font-size: 12px;
    margin-bottom: 3rem;
  }

  .otp-text {
    margin-top: 6rem;
  }
  .btn-otp{
    margin-bottom: 3rem;
    padding: 1rem;
  }
}
