* {
  margin: 0%;
  padding: 0%;
}

.category-container {
  width: 100%;
  min-height: calc(100vh - 60px);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

/* Hide scrollbar for all major browsers */
.category-container {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.category-container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

  /* Category top tab styles */
  .category-top-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    padding: 0.5rem;
    position: sticky;
    top: 0; /* Stick to the top on scroll */
    margin-top: -5rem;
  }
  
  /* Category button styles */
  .category-btn {
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Add item and catalog button styles */
  .additem, .catalog {
    border: 2px solid #600aad;
    padding: 0.5rem;
    border-radius: 50px;
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #600aad;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .catalog {
    margin-left: 1rem;
  }
/* Category card container styles */
.category-card-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 6rem;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    overflow-y: auto;
    height: auto;
    max-height: 37rem;
    position: relative;
    margin-bottom: 2rem;
    padding-bottom: 4rem;
}

/* Hide scrollbar for all major browsers */
.category-card-container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  .category-card-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

/* Additional margin at the bottom of the container */

.category-card-container::after {
    content: '';
    display: block;
    height: 1rem; /* Adjust height as needed */
  }
  
  /* Category-top-tab styles */
  .category-top-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    width: 100%;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    padding: 0.5rem;
    position: sticky;
    top: 0; 
  }
  
  /* Category-btn styles */
  .category-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Button styles */
  .additem, .catalog {
    border: 2px solid #600aad;
    padding: 0.5rem;
    border-radius: 50px;
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #600aad;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .catalog {
    margin-left: 1rem;
  }
  
  /* Box styles */
  .category-container .box {
    background-color: hsl(0, 0%, 100%);
    padding: 15px;
    border: 1px solid #600aad;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    color: rgb(61, 94, 155);
    transition: transform 0.3s;
    width: calc(20% - 16px);
    flex: 0 0 calc(20% - 16px);
    margin: 0;
    box-sizing: border-box;
}

.category-container .box:last-child {
    margin-right: 0;
}
.box img{
  height: 8rem;
}
.card-detail {
    text-align: start;
    margin-bottom: auto;
    margin-top: 10px;
  }
  
  .btn-custom {
    background: #600aad;
    color: white;
    border-radius: 20px;
  }
  
  .text-custom {
    color: #600aad;
    border-radius: 15px;
    width: 20rem;
    margin-left: 1rem;
  }
  
  .card-title {
    margin-top: .5rem;
    font-size: 20px;
    font-weight: 600;
  }
  
  .btn-view-more {
    align-self: flex-end;
    color: #600aad;
  }
  
  /* Large screens (desktops) */
  @media (min-width: 1200px) {
    .main-container {
      width: calc(100% - 20rem);
      margin-left: 350px;
    }
    .box {
      flex: 1 1 calc(20% - 20px); /* 3 cards per row */
    }
    .category-top-tab {
      flex-direction: row; 
    }
}

/* Medium screens (tablets) */
@media (max-width: 1199px) and (min-width: 768px) {
    .main-container {
      width: 100%;
      margin-left: 200px;
    }
    .category-top-tab {
      flex-direction: column; 
      align-items: flex-start;
    }
    .category-btn {
      margin-top: 1rem;
    }
    
  }
  
  @media (max-width: 767px) {
    .category-container{
      padding: 0.5rem;
      margin-bottom: 1rem;
    }
    .box {
     width: calc(50% - 10px);
     flex: 0 0 calc(50% - 10px);
  }
    .main-container {
        width: 100%;
        margin-left: 0px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .category-top-tab {
        flex-direction: column; 
        align-items: flex-start;
        margin-top: 0;
    }
    .category-btn {
        margin-top: 1rem;
        width: 100%;
        justify-content: space-between;
        gap: 10px;
    }
    .search {
        width: 100%;
        margin-left: 0; 
    }
    .search input {
      width: 100%; 
      margin: 0; 
    }
    .search button {
        width: auto; 
        margin-left: 0.5rem; 
    }
    .card-title{
      /* white-space: nowrap; */
    }
    .additem, .catalog {
      width: 48%;
      padding: 0.5rem 1rem;
      font-size: 14px;
      margin-left: 1rem;
    }
    .category-container .box {
      width: calc(50% - 10px);
      flex: 0 0 calc(50% - 10px);
    }
  
    .category-container .category-card-container {
      margin-top: 1rem;
      max-height: 29rem;
    }
  }
