.container {
  width: 100%;
  height: calc(100vh - 60px);
  margin: 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  background: white;
}

/* Hide scrollbar for all major browsers */
.container, .cardContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar, .cardContainer::-webkit-scrollbar {
  display: none;
}


.searchContainer {
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 1rem; */
  flex-wrap: wrap ;
}

.searchContainer input {
  padding: 0.75rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 20px;
  font-family: 'Poppins';
  font-size: 0.9rem;
  background: white;
}

.searchContainer h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #600AAD;
}

.wishlistTitle {
  width: 29%;
  margin: auto;
}

.searchInput {
  width: 49%;
}

.sellUsed {
  width: 18%;
  margin: auto;
}

.sellUsedItem:hover, .viewMore:hover {
  background-color: #600AAD;
  color: white;
}

.sellUsedItem {
  color: #600AAD;
  border: 1px solid #600AAD;
  border-radius: 45px;
  padding: 0.3rem;
  white-space: nowrap;
}

.productsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
}

.productCard {
  background: white;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.productImage {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.productName {
  font-family: 'Poppins';
  font-size: 0.9rem;
  font-weight: 500;
  color: #1f2937;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.priceContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: 0.5rem 0;
}

.originalPrice {
  color: #6b7280;
  text-decoration: line-through;
  font-size: 0.85rem;
}

.discountedPrice {
  color: #600AAD;
  font-weight: 600;
  font-size: 1rem;
}

.discount {
  color: #600AAD;
  font-size: 0.8rem;
  background: rgba(96, 10, 173, 0.1);
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
}

.viewMore {
  margin-top: auto;
  padding: 0.5rem 1rem;
  border: 1px solid #600AAD;
  border-radius: 20px;
  color: #600AAD;
  font-size: 0.9rem;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
}

.ratingContainer {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.star {
  color: #fbbf24;
}

.rating {
  font-size: 0.875rem;
  margin-left: 0.25rem;
}

.noProducts {
  grid-column: 1 / -1;
  text-align: center;
  padding: 2rem;
  font-family: 'Poppins';
  color: #6b7280;
}

.categoryName {
  font-size: 0.875rem;
  white-space: nowrap;
}

.storeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 2rem 0;
  width: 60%;
  margin: auto;
}

.storeName {
  font-size: 55px;
  font-weight: bold;
  font-family: 'Lily Script One';
  margin-bottom: 1rem;
  white-space: nowrap;
}

.storeContainer {
  display: flex;
  width: 100%;
  overflow-x: auto;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
}

.storeCategoryContainer {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  width: 70%;
  gap: 1rem; /* Add spacing between items */
  overflow-x: hidden;
  padding: 1rem;
}

.viewShopButton {
  background-color: white;
  border: 1px solid #600AAD;
  color: #600AAD;
  padding: 0.5rem;
  font-family: poppins;
  text-align: center;
  align-items: baseline;
  font-size: 18px;
  cursor: pointer;
  white-space: nowrap;
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  margin-top: 0;

}

.productCard {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
  font-family: poppins;
  font-size: 18px;
}

.productImage {
  width: 100%;
  height: 10rem;
  object-fit: cover;
  margin-bottom: 1rem;
}

.productName {
  font-size: 16px;
  font-weight: 500;
  font-family: poppins;
  color: black;
}

.priceContainer {
  display: flex;
  align-items: center;
}

.originalPrice {
  color: #6b7280;
  text-decoration: line-through;
}

.discountedPrice {
  color: #600AAD;
  margin-left: 0.5rem;
}

.discount {
  color: #600AAD;
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

.ratingContainer {
  display: flex;
  align-items: center;
}

.star {
  color: #fbbf24;
}

.rating {
  font-size: 0.875rem;
  margin-left: 0.25rem;
}

.viewDetailsButton {
  width: 100%;
  color: #600AAD;
  border: 1px solid #600AAD;
  border-radius: 9999px;
  padding: 0.25rem 0;
  background: none;
  cursor: pointer;
}
.searchInputphone{
  display: none;
}
.edit{
  height: 1.5rem;
  width:2rem;
  display: flex;
  justify-content:flex-end;
  margin-left: 90%;
cursor: pointer; 
font-size: 1.5rem;
color: red;
margin-bottom: .5rem;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .container {
    margin-right: 0;
    margin-top: 0%;
    padding: 0rem;
    height: 100%;
    overflow-y: auto;
    max-height: 33rem;
  }

  .edit{
    margin-left: 80%;
  }
  .searchInput{
    display: none;
  }
  .searchInputphone{
    display: block;
  }
  .searchContainer{
    margin: 0%;
    margin-bottom: .5rem;
  }
.sellUsed{
  width: 40%;
}
  .productsGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    /* border: 1px solid black; */
    margin-top: .5rem;
  }

  .toggleContainer {
    width: 90%;
    margin: 0.5rem auto;
  }

  .productImage {
    height: 20rem;
  }

  .productName {
    font-size: 0.8rem;
    white-space: nowrap;
  }
}

@media (max-width: 640px) {
  .productsGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    padding: 0.25rem;
  }

  .productCard {
    padding: 0.75rem;
  }

  .productImage {
    height: 120px;
    margin-bottom: 0.75rem;
  }

  .productName {
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
  }

  .priceContainer {
    gap: 0.25rem;
  }

  .originalPrice {
    font-size: 0.75rem;
  }

  .discountedPrice {
    font-size: 0.85rem;
  }

  .discount {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
  }

  .viewMore {
    padding: 0.4rem 0.75rem;
    font-size: 0.8rem;
  }
}

