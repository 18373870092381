.container {
    width: 100%;
    height: 100vh;
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1rem;
    position: relative;
  }
  
  /* Hide scrollbar for all major browsers */
  .container {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }
  
  .container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  

  .personInfo{
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    flex-direction: column;
    gap: 15px;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    height: auto; 
    margin-bottom: 80px; /* Add space for bottom nav */
  }
  .column{
    display: flex;
    flex-direction: column;
  }

  .profileimg{
    display: flex;
    /* justify-content: center;
    align-items: center; */
    gap: 20px;
    width: 30%;
  }

  .profileimg img{
    border: 1px solid black;
  }
  p{
    color: #6000AD;
  }
  .info{
    display: flex;
    gap: 20px;
    width: 100%;
  }
  .name{
    width: 70%;
  }
  .column{
    width: 100%;
    align-items: start;


  }
  input{
    justify-content: start;
    align-items: start;
  }
  .type{
    display: flex;
    width: 100%;
    gap: 10px;
    /* align-items: center; */
    /* justify-content: center; */
  }
  textarea{
    border: 1px solid rgb(206, 201, 201);
    width: 100%;
  }
  .img{
    height: 5rem;

    width: 5rem;
  }
  .imgsig{
    height: 8rem;

    width: 15rem;
  }
  .saveCancel{
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 10px;
    margin-top: 2rem;

}
.save{
background: #600AAD;
color: white;
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid #600AAD;
border-radius: 50px;
padding: .5rem;
width: 8rem;
    
}
.cancel{
  color: #600AAD;
    display: flex;
    justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid #600AAD;
border-radius: 50px;
padding: .5rem;
width: 8rem;

}

/* Add media queries for mobile/tablet */
@media (max-width: 768px) {
  .container {
    margin: 0;
    padding: 0.5rem;
    height: calc(100vh - 80px); /* Adjust for bottom nav */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .personInfo {
    margin-bottom: 70px;
    padding: 0.8rem;
  }

  .type {
    flex-direction: column;
    width: 100%;
  }

  .type input {
    width: 100%;
  }

  .saveCancel {
    width: 100%;
    justify-content: center;
    margin-bottom: 80px;
  }
}

@media (max-width: 480px) {
  .container {
    height: calc(100vh - 70px);
  }

  .info {
    flex-direction: column;
  }

  .profileimg {
    width: 100%;
  }

  .name {
    width: 100%;
  }

  .save, .cancel {
    width: 45%;
    font-size: 14px;
  }
}