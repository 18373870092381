.category-container {
    min-height: 100vh;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.category-left-screen {
    width: 40%;
    height: 100vh;
    background: #600AAD;
    position: fixed;
}

.category-left-screen img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.category-right-screen {
    width: 60%;
    margin-left: 40%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
}

.category-form-container {
    width: 100%;
    max-width: 800px;
    padding: 2rem;
}

.category-web-img {
    text-align: center;
    margin-bottom: 2rem;
}
.category-web-img img{
  border: none;
    height: 6rem;
    width: 10rem;
}
.logo {
    height: 40px;
    border-radius: 5%;
}

.tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.tabs {
    display: flex;
    gap: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    padding: 5px;
}

.tab {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
    color: #666;
    border-radius: 40px;
    transition: all 0.3s ease;
}

.active-tab {
    background: #600AAD;
    color: white;
}

.search-bar {
    width: 300px;
}

.search-bar input {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 20px;
    font-size: 14px;
}

.category-details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    margin: 2rem 0;
}

.detail {
    border: 2px solid #600AAD;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background: none;
}

.detail:hover {
    background: #f8f5ff;
}

.detail img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.selected-category {
    background: #f8f5ff;
}

.btn-submit {
    width: 100%;
    padding: 12px;
    border: none;
    background: #600AAD;
    color: white;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
}

.btn-submit:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.btn-submit:hover:not(:disabled) {
    background: #500990;
}

.category-section {
    margin-bottom: 2rem;
}

.section-title {
    font-size: 1.5rem;
    color: #600AAD;
    margin-bottom: 1rem;
    font-weight: 600;
}

@media (max-width: 768px) {
    .category-container {
        flex-direction: column;
        overflow: hidden;
        height: 100vh;
    }

    .category-left-screen {
        display: none;
    }

    .category-right-screen {
        width: 100%;
        margin-left: 0;
        padding: 1rem;
        height: 100vh;
        overflow-y: auto;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .category-form-container {
        padding: 1rem;
        min-height: 100%;
        padding-bottom: 80px;
    }

    .btn-submit {
        margin-bottom: 20px;
    }

    .category-details {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        margin-bottom: 1rem;
    }

    .category-section {
        margin-bottom: 1.5rem;
    }

    .section-title {
        font-size: 1.25rem;
        margin-bottom: 0.75rem;
    }

    .tabs-container {
        flex-direction: column;
        gap: 1rem;
    }

    .search-bar {
        width: 100%;
    }

    .tabs {
        width: 100%;
    }

    .tab {
        flex: 1;
        text-align: center;
    }
}
