.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

h2 {
  margin-top: 0;
  color: #600AAD;
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input[type="text"],
.formGroup input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.formGroup small {
  display: block;
  margin-top: 5px;
  color: #666;
}

.saveButton {
  background: #600AAD;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
}
.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  animation: spin 1s linear infinite;
  color: black;
  font-size: 2rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}   


@media (max-width: 768px) {
  .modalContent {
    width: 95%;
    padding: 15px;
  }

  .saveButton {
    padding: 8px 15px;
  }
}
