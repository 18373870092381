/* General Styles */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.signUp-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow: hidden;
}

.signUp-left-screen img {
  width: 100%;
  object-fit: cover;
}

.signUp-right-screen {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signUp-form-container {
  border-radius: 8px;
  background: white;
  padding: 2rem;
  max-width: 600px;
  width: 100%;
  margin: auto;
  height: auto;
  gap: 10px;
}

.signUp-head {
  font-size: 3rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 80px;
  text-align: center;
  color: #6f0aad;
  
}

.signUp-details {
  width: 100%;
  display: flex;
  text-align: start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.details input {
    border: 1px solid gray;
    border-radius: 6px;
    width: 22rem;
    height: 2rem;
    padding-left: 1rem;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  gap: 10px;
  margin-bottom: 10px;
}

.location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  gap: 10px;
  margin-top: 1rem;
}

.location input {
    background:#DEE2E6    ;
  border: 1px solid gray;
  border-radius: 6px;
  width: 8rem;
  height: 2rem;
  padding-left: 1rem;
}

.btn-signUp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}
.btn-signUp :disabled{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  background: #faf4f4;
}

.btn-signUp button,
.btn-signUp button:hover {
  padding: 16px;
  border-radius: 8px;
  background: #600aad;
  color: white;
  width: 70%;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.btn-signUp button:disabled {
  background: #ccc;
  cursor: not-allowed;
  border: none;
}

/* Media Queries for Responsive Design */

/* For tablets and smaller desktops */
@media (max-width: 1024px) {
  .signUp-right-screen {
    width: 80%;
  }

  .signUp-head {
    font-size: 2.5rem;
    line-height: 60px;
  }

  .details input,
  .location input {
    width: 18rem;
    height: 1.8rem;
  }

 

  .location input {
    width: 80%;
  }

  .btn-signUp button {
    width: 80%;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .signUp-left-screen {
    display: none;
  }

  .signUp-web-img {
    display: none;
  }

  .signUp-right-screen {
    width: 100%;
    padding: 1rem;
}


  .signUp-form-container {
    padding: 1.5rem;
  }

  .signUp-head {
    text-align: left;
    font-size: 2rem;
    line-height: 40px;
    margin-bottom: 2rem;
  }

  .details input,
  .location input {
    width: 100%;
    height: 2rem;
  }
 

  .btn-signUp button {
    width: 90%;
    margin-top: 8rem;
  }
}

/* For small mobile devices */
@media (max-width: 480px) {
  .signUp-head {
    font-size: 2rem;
    line-height: 30px;
    margin: 1rem;
  }
.signUp-container{
    background: rgb(245, 243, 243);
}
  .signUp-form-container {
    padding: 1rem;
    background: rgb(245, 243, 243);
  }

  .details input,
  .location input {
    width: 100%;
    height: 3rem;
  }
  .signUp-details{
    margin-bottom: 4rem;
  }
.location {
    width: 90%;
}
  .btn-signUp button {
    width: 100%;
  }
}
