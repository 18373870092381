.container {
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow-y: auto !important;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.container::-webkit-scrollbar {
  display: none;
}

.pageTitle {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}

.detailCard {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  border: 1px solid #600AAD;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.leftSection {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rightSection {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
}

.eventName {
  font-size: 22px;
  font-weight: 500;
  color: #333;
  margin: 0 0 10px 0;
}

.location {
  font-size: 16px;
  color: #666;
  margin: 0;
}

.dateInfo {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.dateLabel {
  font-size: 16px;
  color: #666;
  margin: 0 10px 0 0;
}

.dateValue {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  margin: 0;
}

.sectionTitle {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin: 0 0 15px 0;
}

.categorySection {
  margin-bottom: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.categoryList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.categoryItem {
  font-size: 16px;
  color: #600AAD;
  margin-bottom: 8px;
}

.descriptionSection {
  margin-bottom: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.descriptionText {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin: 0;
}

.bidSection {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 50px;
  border: 1px solid #600AAD;
}

.noBids {
  text-align: center;
  padding: 30px 0;
  color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 15px;
    height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
  }
  
  .pageTitle {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .detailCard, .bidSection {
    padding: 15px;
  }
  
  .headerSection {
    flex-direction: column;
  }
  
  .rightSection {
    align-items: flex-start;
    margin-top: 15px;
  }
  
  .eventName {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 10px;
  }
  
  .detailCard, .bidSection {
    padding: 12px;
  }
  
  .dateInfo {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .dateLabel, .dateValue {
    font-size: 14px;
  }
  
  .eventName {
    font-size: 18px;
  }
  
  .location, .descriptionText, .categoryItem {
    font-size: 14px;
  }
  
  .sectionTitle {
    font-size: 16px;
  }
}
