
/* splashscreen */

.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #600aad;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    z-index: 9999;
  }
  
  .splash-screen.fade-out {
    opacity: 0;
    background: #600aad;
  }
  
  .splash-logo {
    width: 150px;
    height: 150px;
    animation: bounce 2s infinite;
  }
  
  .splash-text {
    color: white;
    font-size: 24px;
    margin-top: 20px;
    animation: fadeIn 2s ease-in-out;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  