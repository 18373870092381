.container {
    width: 100%;
    height: calc(100vh - 60px);
    margin: 0;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
}

/* Hide scrollbar for all major browsers */
.container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;    /* Firefox */
}

.container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    width: 100%;
    height: 100%;
    /* background-color: white; */
    border-radius: 8px;
    box-sizing: border-box;
}

.home {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.box1, .box2, .box3, .box4 {
    margin: .5rem;
    border-radius: 12px;
    padding: 1rem;
    width: 45%;
    cursor: pointer;
}
/* .box3 Link{
    text-decoration: underline !important;
    
} */
 .seeTenderButtons{
    text-decoration: underline;
    color: blue;
 }

.box1 { background: #DAC2EF; border: 2px solid #ad75de;}
.box2 { background: #FFC5A8; border: 2px solid #dfa486;}
.box3 { background: #84F484; border: 2px solid #69d869;}
.box4 { background: #9AB8FC; border: 2px solid #597ccd;}

.up {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 50%;
}

.down {
    margin-top: 0;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

p {
    color: gray;
}

.work {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f9f9f9; */
    overflow: hidden;
    padding: 0;
}

.text {
    font-size: 22px;
}

.workBox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.box5, .box6 {
    border-radius: 12px;
    width: 35%;
    margin: 1rem;
    padding: 1rem;
    background: white;
    display: flex;
    height: 9rem;
    cursor: pointer;
    justify-content: start;
    gap: 20px;
}

.img img {
    width: 2.5rem;
}

.add {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    object-fit: contain;
    border-radius: 8px;
}

/* Media Queries for Mobile Screens */
@media (max-width: 480px) {
    .container {
        height: calc(100vh - 60px);
        overflow-y: auto;
    }
    
    .cardContainer {
        height: auto;
        min-height: 100%;
        padding: 0.5rem;
    }

    .up img {
        max-width: 24px;
        height: auto;
    }

    .down {
        padding: 0.5rem;
    }

    .work {
        margin-top: 1rem;
        padding: 0.5rem;
    }

    .add {
        max-height: 300px;
    }

    .box1, .box2, .box3, .box4 {
        height: 300px;
        width: calc(50% - 0.5rem);
        margin: 0.25rem;
        padding: 0.5rem;
    }

    .up {
        height: 50%;
    }

    .down {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

/* Media Queries for Medium Screens */
@media (max-width: 768px) {
    .home {
        width: 100%;
    }

    .box1, .box2, .box3, .box4 {
        height: 110px;
        width: calc(50% - 1rem);
        margin: 0.5rem;
    }
}

/* Media Queries for Tablets */
@media (max-width: 1024px) {
    .home {
        width: 100%;
    }

    .box1, .box2, .box3, .box4 {
        width: calc(50% - 1rem);
    }
}