.container {
    width: 100%;
    height: 100vh;
    padding: 1rem;
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    padding-bottom: 80px;
    margin-bottom: 2rem;
  }
  
  /* Hide scrollbar for all major browsers */
  .container {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }
  
  .container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  

  .personInfo{
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    flex-direction: column;
    gap: 8px;
    padding: 1rem;
    margin-top: 1rem;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    height: auto; /* Set a fixed height */
    position: relative;
  }
  .nameLogo img{
    height: 3rem;
    width: 3rem;
  }
  .nameLogo,.phone,.email{
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .nameLogo h5{
    color: #600aad;
    font-weight: 700;
  }
.logo img{
  
    height: 3rem;
    width: 3rem;
}

  .info{
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    flex-direction: column;
    gap: 8px;
    padding: 1rem;
    margin-top: 1rem;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    height: auto; /* Set a fixed height */
    position: relative;
  }
  p{
    color: gray;
    font-size: 16px;
    font-weight: 500;
  }
  .business, .financial{
    
      display: flex;
      justify-content: left;
      align-items: center; 
      gap: 20px;
      padding: 1rem;
      margin-top: 1rem;
      background-color: white;
      box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
      height: auto; /* Set a fixed height */
      position: relative;
    
  }
  .logo{
    height: 3rem;
    width: 2rem;
  }
  .btn{
   
    display: flex;
    justify-content: center;
    align-items:flex-end;
    border: 1px solid #600aad;
  width: 7rem;
    background: #600aad;
    color: white;
    border-radius: 50px;
    padding: .5rem;
    margin-top: .5rem;
    align-items: center;
    font-size:16px;
    font-weight: 500;
  }
  .type{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    gap: 8px;

  }
  .signature{
    display: flex;
    justify-content: left;
    align-items: self-start; 
    gap: 15rem;
    padding: 1rem;
    margin-top: 1rem;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    height: auto; /* Set a fixed height */
    position: relative;
  }
  .signature p{
    font-size: 22px;
  }

  
  .signature img {
    width: 10rem;
    height: 3rem;
    object-fit: cover; /* Fills the div while maintaining the aspect ratio */
  }
  
/* Media query for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .container {
    margin: 0;
    padding: 0.5rem;
    height: calc(100vh - 80px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 70px;
  }

  .personInfo, .info, .business, .financial, .signature {
    margin-bottom: 1rem;
  }

  .btn {
    position: relative;
    margin-bottom: 80px;
  }

  .personInfo, .info, .business, .financial, .signature {
    gap: 4px;
    padding: 0.8rem;
    margin-top: 0.8rem;
  }

  .nameLogo h5, p {
    font-size: 14px; /* Smaller text for tablet screens */
  }

  .btn {
    width: 6rem;
    font-size: 14px;
    padding: 0.4rem;
  }

  .signature img {
    width: 8rem;
    height: 2.5rem;
  }
}

/* Media query for mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .container {
    margin: 0;
    padding: 0.5rem;
    min-height: 100%;
    max-height: calc(100vh - 70px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 80px;
  }

  .personInfo, .info, .business, .financial, .signature {
    flex-direction: column;
    gap: 2px;
    padding: 0.5rem;
    margin-top: 0.5rem;
  }

  .nameLogo img, .logo img {
    height: 2rem;
    width: 2rem;
  }

  .nameLogo h5, p {
    font-size: 12px; /* Smaller text for mobile screens */
  }

  .btn {
    width: 5rem;
    font-size: 12px;
    padding: 0.3rem;
  }

  .signature {
    gap: 5rem; /* Reduced gap for compact layout */
  }

  .signature img {
    width: 6rem;
    height: 2rem;
  }
}
