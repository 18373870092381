.contactContainer {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 10px;
  }
  
  .lastUpdated {
    font-size: 14px;
    text-align: center;
    color: #777;
    margin-bottom: 20px;
  }
  
  .contactInfo {
    line-height: 1.8;
  }
  
  .heading {
    font-size: 18px;
    font-weight: bold;
    color: #444;
    margin-bottom: 5px;
  }
  
  .text {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
  }
  
  .link {
    color: #007bff;
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  