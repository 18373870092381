  /* Category container styles */
  .container {
    width: 100%;
    height: 100vh;
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;  
  }
  
  /* Hide scrollbar for all major browsers */
  .container {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }
  
  .container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
   
  .orderTopTab {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background: white; */
    border: none;
    /* box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1); */
    padding: 0.5rem;
    /* margin-right: 2rem;
    margin-left: 2rem; */
    /* border-radius: 30px; */
    position: sticky;
    top: 0; 
    margin-top: 0rem;
  }
  .tab1{
    display: flex;
    width: 95%;
    justify-content: space-between;
    margin-top: .5rem;
   
  }
  
  
  .tab2 {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    gap: 10px; /* Adjust this gap if needed */
    flex-wrap: nowrap; /* Prevent wrapping to the next line */
  width: 95%;
  margin-top: 1rem;
  }
  
.tab2 button{
width: 10rem;
}
  
  .tab2 img {
    max-width: 1rem; /* Ensure icons stay a reasonable size */
  }
  .addBtn {
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
        
  .btnCustom {
    background: #600aad;
    color: white;
    border-radius: 50px;
    width: 6rem;

  }
  .btnCustoms {
    display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      font-size: 1rem;
      max-width: 6rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: none;
      border-radius: 5px;
      /* background-color:white; */
      color:#600aad;
      border-radius: 50px;
      font-size: 20px;
      font-weight: 400;
      border: 2px solid #600aad;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

  }
  
  .btnCustoms.active {
    background: #600aad;
    color: white;
    border-color: #600aad;
  }
  
  
 .addOrder{
   border: 2px solid #600aad;
   padding: 0.5rem;
   border-radius: 50px;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #600aad;
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
}
.textCustom{
  border-radius: 20px;
  width: 50rem;
  padding: .5rem;
  border: 1px solid #600aad;
  /* width: 3rem; */
}

.textCustom input::placeholder{
  color: #600aad;
}

.partyCardContainer {
  display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 1rem;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    overflow-y: auto;
    height: auto;
    max-height: 37rem;
    position: relative;
    /* margin-bottom: 2rem; */
    padding-bottom: 4rem;
}
.partyCardContainer {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.partyCardContainer::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.box {
  background-color: hsl(0, 0%, 100%);
  padding: 15px; /* Decreased padding for smaller size */
  border: 1px solid #600aad;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  color: rgb(61, 94, 155);
  transition: transform 0.3s;
  flex: 1 1 calc(33.33% - 20px); /* Decrease the percentage for smaller boxes */
  max-width: calc(33.33% - 20px); /* Ensure the boxes don’t exceed the row width */
  box-sizing: border-box; /* Make sure padding doesn’t affect the width */
  margin-bottom: 20px; /* Space between rows */
border: 2px solid #600aad;
gap: 1rem;
}


.cardImg{
height: 8rem;
width: 10rem;
object-fit: cover;
  
}
.data{
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}
.infoDiv{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  /* width: 30%; */
}



.btnType {
  display: flex;
  justify-content: center;
    width: 100%;
  margin-top: .5rem;
  align-items: center;
  padding: 0.2rem;
  font-size:16px;
  font-weight: 500;
  border-radius: 8px;
  
}
.gray{
  background: #666666;
  color: white;
}
.green{
  background: #5BBD5B;
  color: white;
}
.red{
  background: #FF1A00;
  color: white;
}
.purple{
  background: #9A008B;
  color: white;
}

.name{
font-family: Poppins;
font-size: 22px;
font-weight: 500;
line-height: 30px;
color: #600aad;
display: flex;
justify-content: flex-start;
width: 100%;
text-align: left;
}
.email ,.date,.value{
  margin-top: 1rem;
  font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 24px;
color: black;
display: flex;
justify-content: flex-start;
width: 100%;
}

  

/* Media query for tablets (width 768px and below) */
@media (max-width: 768px) {
  .container {
    margin-right: 1rem;
    padding: 0.5rem;
    height: 100vh; /* Ensure container still takes full height */
  }
  
  .orderTopTab {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }

 
  .partyCardContainer {
    padding: 0.5rem;
    gap: 10px;
    margin-top: 0%;
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .tab1 {
    width: 100%;
    /* flex-direction: column; */
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .addOrder {
    font-size: 14px;
    padding: 1rem;
    height: 2rem;
    align-items: center;
    text-align: center;
  }

  .addOrder img{
    height: 1.5rem;

  }
  .tab2 {
    gap: 0px;
    width: 100%;
    display: flex;
    justify-content: stretch;
    height: 4rem;
  }
 

  .tab2 button{
    width: 4rem;
    font-size: .5rem;
    padding: .4rem;
  }
.btnCustom{
   display: none;
  }
 
  .btnCustoms{
    font-size: 0.9rem; /* Slightly reduce font size */
    padding: 8px 16px; /* Adjust padding */
    max-width: 150px; 
    white-space: nowrap;
   }

 .partyCardContainer {
    max-height: 25rem; /* Set a height to make it scrollable */
    padding: 0.5rem;
    gap: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    margin-bottom: 2rem;
  }

  .box {
    flex: 1 1 calc(50% - 20px); /* Make boxes take 50% width on tablets */
    width: 100%;
    margin-bottom: 1rem;
    padding: 10px; /* Smaller padding for smaller screens */
  }

  .cardImg {
    height: 6rem; /* Reduce the image height */
  }

  .infoDiv {
    width: 60%; /* Adjust the info div to fit better on tablets */
    margin-top: 0.5rem;
  }
  
  .name {
    font-size: 18px; /* Slightly smaller font size */
  }
  
  .email, .date, .value {
    font-size: 12px; /* Reduce the text size for smaller screens */
  }

  .btnType {
    font-size: 14px; /* Adjust button font size */
    width: 50%; 
  }
  
  .textCustom {
    width: 100%;
    margin-left: 0;
    font-size: 14px;
  }
}

/* Media query for mobile devices (width 480px and below) */
@media (max-width: 480px) {
  .container {
    margin-right: 0;
    padding: 0.25rem;
    height: 100vh;
  }
  
  .orderTopTab {
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0%;
    margin-top: 0;
    gap: 5px;
  }
  .btnCustoms{
    font-size: 0.8rem !important; /* Further reduce font size */
    font-weight: 600;
    padding: .3rem; /* Adjust padding for small buttons */
    max-width: 120px; /* Make the button narrower */
    white-space: normal; /* Allow text to wrap for smaller buttons */
    text-align: center;
  }

  .tab1, .tab2 {
    margin-top: 0.5rem;

    width: 100%;
    gap: 5px;
  }


  .addOrder {
    font-size: 12px;
    padding: 1rem;
    display:flex;
    white-space: nowrap;
    justify-content: center;
align-items:center ;
text-align: center;
    height: 2rem;
  }

  .partyCardContainer {
    padding: 0.5rem;
    margin-top: 0.5rem;
    gap: 10px;
    height: 60%;
  }

  .box {
    flex: 1 1 100%; /* Make each box take full width on mobile */
    max-width: 100%;
    margin-bottom: 10px;
    padding: 8px;
  }

  .cardImg {
    height: 4rem; /* Reduce the image size for mobile */
  }

  .infoDiv {
    width: 40%; /* Make the info div take full width */
    margin-top: 0.5rem;
  }

  .name {
    font-size: 16px; /* Smaller font size */
  }
  
  .email, .date, .value {
    font-size: 12px; /* Reduce text size for mobile */
  }

  .btnType {
    font-size: 12px;
    width: 60%;
  }

  .textCustom {
    width: 100%;
    font-size: 12px;
    padding:.2rem;
  }
}