.container {
    width: 100%;
    height: calc(100vh - 60px);
    margin: 0;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    
}

/* Hide scrollbar for all major browsers */
.container , .cardContainer , .categoriesContainer , .categoriesList , .storeContainer , .storeCategoryContainer{
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;    /* Firefox */
}

.container::-webkit-scrollbar  , .cardContainer::-webkit-scrollbar , .categoriesContainer::-webkit-scrollbar , .categoriesList::-webkit-scrollbar , .storeContainer::-webkit-scrollbar , .storeCategoryContainer::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.cardContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    /* margin-top: 2rem; */
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    overflow-y: auto;
    /* height: auto; */
    height: calc(100vh - 60px);
    position: relative;

  
}
.header {
  background-color: #600AAD;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.backButton {
  color: white;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
}

.backIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.categoriesContainer {
    width: 100%; /* Full width for the container */
  display: flex; /* Flexbox for horizontal layout */
  justify-content: space-evenly;
  flex-direction: row;
  gap: 1rem; /* Space between items */
  position: relative;
  /* overflow: hidden; */
  margin-bottom: 1rem;
  
}


.toggleContainer {
  width:30%;
  display: flex;
  margin: auto;
  margin-left: 1rem;
  /* margin-bottom: 16px; */
  background: #f3f4f6;
  padding: .5rem;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.toggleButton {
  padding: .5rem;
  border: none;
  border-radius: 40px;
  font-size: 14px;
  width: 50%;
  cursor: pointer;
  background: transparent;
  color: #6b7280;
  font-family: poppins;
font-size: 18px;

  transition: all 0.3s ease;
}

.activeToggle {
  background: #600AAD;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 40px;
  font-size: 14px;
  width: 50%;
  font-family: poppins;
font-size: 18px;
  
}

 

.tab1{
    padding: 8px 16px;
    border-radius: 20px;
    font-family: poppins;
  font-size: 18px;
  cursor: pointer;
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.searchContainer{
    width: 50%;
    height: auto;
}   
.searchContainer input{
    padding: .5rem;
    align-items: center;
}
.manageInventory{

  display: flex;
}
.manageInventory span{
    /* width: 20%; */
 display: flex;
    color: #600AAD;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    cursor: pointer;
    
    /* margin-left: 10rem; */
}

.categoriesList {
  display: flex;
  width: 100%;
  overflow-x: auto;
  gap: 60PX;
  margin: auto;
}

.categoryItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #818181;
  font-family: poppins;
  font-size: 18px;
  cursor: pointer;
}

.categoryImage {
  width: 4rem;
  height: 4rem;
  background-color: #e5e7eb;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}
.categoryImage img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.categoryName {
  font-size: 0.875rem;
  white-space: nowrap;
}

.storeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  text-align: center;
  padding: 2rem 0;
  width: 60%;
  margin: auto;
}

.storeName {
  font-size: 55px;
  font-weight: bold;
  font-family: 'Lily Script One';
    margin-bottom: 1rem;
    white-space: nowrap;
}

.storeContainer{
    display: flex;
    width: 100%;  
    overflow-x: auto;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1rem;
}
.storeCategoryContainer{
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
width: 70%;
  gap: 1rem; /* Add spacing between items */
  overflow-x: hidden;
  padding: 1rem;
}

.viewShopButton {
  background-color: white;
  border: 1px solid #600AAD;
  color: #600AAD;
  padding: 0.5rem;
  font-family: poppins;
  text-align: center;
  align-items: baseline;
  font-size: 18px;
  cursor: pointer;
  white-space: nowrap;
}

.productGrid  {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;
  width: 100%;
 
}

.productCard {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
  font-family: poppins;
  font-size: 18px;
  /* width: fit-content; */
  
}
.productCardadd{
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1rem;
    font-family: poppins;
    font-size: 18px;
    width: fit-content;
}

.productImage {
  width: 100%;
  height: 10rem;
  object-fit: cover;
  margin-bottom: 1rem;
}
.productImageadd{
   
        width: 100%;
        height: 6rem;
        object-fit: cover;
        margin-bottom: 1rem;
      
}

.productName {
  font-size: 16px;
  font-weight: 500;
  font-family: poppins;
  color: black;
}

.priceContainer {
  display: flex;
  align-items: center;
  /* margin-top: 0.5rem; */
}

.originalPrice {
  color: #6b7280;
  text-decoration: line-through;
  }
  
  .discountedPrice {
  color: #600AAD;
  margin-left: 0.5rem;
 
  }
  

.discount {
  color: #600AAD;
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

.ratingContainer {
  display: flex;
  align-items: center;
}

.star {
  color: #fbbf24;
}

.rating {
  font-size: 0.875rem;
  margin-left: 0.25rem;
}

.viewDetailsButton {
  width: 100%;
  color: #600AAD;
  border: 1px solid #600AAD;
  border-radius: 9999px;
  padding: 0.25rem 0;
  background: none;
  cursor: pointer;
}

.viewDetailsButtonadd{
    margin-top: .5rem;
  
    display: flex;
justify-content: flex-end;
    background: none;
    cursor: pointer;
    /* width: 100%; */
}
.viewDetailsButtonadd button{
  color: #600AAD;
  border: 1px solid #600AAD;
  border-radius: 9999px;
  padding: 0.3rem;
  white-space: nowrap;
  
}
/* Add hover effects */
.viewShopButton:hover,.sellUsedItem:hover ,.viewDetailsButtonadd button:hover,
.viewDetailsButton:hover {
  background-color: #600AAD;
  color: white;
}
.sellUsedItem{
  color: #600AAD;
  border: 1px solid #600AAD;
  border-radius: 45px;
  padding: 0.3rem;
  white-space: nowrap;
}
.phonemanageInventory{
  display: none;
}
.phonetoggleContainer{
  display: none;
}
.phonetab1{
  display: none;
}
.additemContainer{
  border: 1px solid #e5e7eb;
  background: #CDD9F6;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
border-radius: 8px;
align-items: center;
display: flex;
justify-content: center;
cursor: pointer;
}
.additemContainer img{
  /* margin-top: auto; */

}
.edit{
  /* border: 1px solid black; */
  height: 1.5rem;
  width:2rem;
  /* display: flex; */
  /* justify-content:flex-end; */
  margin-left: 90%;
cursor: pointer
;  margin-top: 0%;
}
/* Add responsive styles */
@media (max-width: 640px) {
  .productGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .toggleContainer{
    display: none;
  }
  .toggleButton{
    /* border: 7px solid black; */
    width: 50%;
    white-space: nowrap;
  }
  .tab1{
    display: none;
  }
  .phonetoggleContainer{
    width:100%;
    display: flex;
    margin: auto;
    /* margin-bottom: 16px; */
    background: #f3f4f6;
    padding: .2rem;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
.phonetab1{
  padding: 8px 16px;
  border-radius: 20px;
  font-family: poppins;
font-size: 18px;
cursor: pointer;
width: 100%;
display: flex;
justify-content: space-evenly;
align-items: center;
}
.searchContainer{
  width: 100%;
}
  .container {
    height: calc(100vh - 60px);
    overflow-y: auto;
}
.manageInventory{
  display: none;
}
.phonemanageInventory{
    display: flex;
justify-content: space-around;
    color: #600AAD;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
      cursor: pointer;
      
      /* margin-left: 10rem; */
    }
    .phonemanageInventory span{
    display: flex;
    gap: 10px;

  }

.viewShopButton{
  /* width: 10%; */
  font-size: 1rem;
  margin: auto;
  padding: .2rem;
  white-space: nowrap;
}
.originalPrice {
  color: #6b7280;
  text-decoration: line-through;
  font-size: 10px;
  }
  
  .discountedPrice {
  color: #600AAD;
  margin-left: 0.5rem;
  font-size: 12px;
  font-weight: 600;
  }
  

}

/* Media Queries */
@media (max-width: 640px) {
    .container {
    
        margin-right: 0;
        margin-top: 0%;
        padding: 0rem;  
        height: 100%;
        overflow-y: auto;
        max-height: 37rem;
    }
    .cardContainer{

            max-height: 34rem; /* Set a height to make it scrollable */
            padding: 0rem;
            gap: 10px;
            margin-bottom: 2rem;
          
    }
    

  .productGrid {
    grid-template-columns: 1fr;
    padding: 0.5rem;
    margin-bottom: 2rem;
  }

  .categoryItem {
    width: 70px; /* Smaller on mobile */
  }

  .categoryImage {
    /* width: 3rem;
    height: 3rem; */
    width: 100%;
  }
.storeContainer{
  width: 100%;
  padding: 0;

}
  .storeHeader{
    width: 30%;
  }
  .storeName {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 900;
  }
  .storeContainer{
    width: 100%;
  }
.categoriesContainer{
    margin-top: 0%;

}
.storeCategoryContainer{
    display: flex;
    width: 70%;
    border: none;
}
  .storeHeader {
    /* padding: 1rem 0; */
    /* width: 30%; */
    font-size: 10px;
    overflow-wrap: break-word;

  }

  .productCard {
    padding: 0.75rem;
    gap:0;

  }
.productCardadd{
width: 50%;
padding: 0.75rem;
}
.viewDetailsButtonadd{
padding: 0.25rem;
margin: auto;
/* font-size: 1px; */
width: fit-content;
display: flex;
justify-content:flex-end;
align-items: self-end;
/* margin-left: auto; */
margin-top: .5rem;
}
.viewDetailsButtonadd button{
  font-size:.6rem !important;
  justify-content: end;

}
  .productImage {
    height: 20rem;
  }

  .productName {
    font-size: 0.8rem;
    white-space: nowrap;
  }
  .productCardadd{
    /* border: none; */
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .container {
    height: calc(100vh - 60px);
    top: 60px;
  }

  .productGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }

  .categoryItem {
    width: 75px;
  }
}

@media (min-width: 1025px) {
  .productGrid {
    grid-template-columns: repeat(4, 1fr);
    max-width: 1200px;
    margin: 0 auto;
  }

  .container {
    height: calc(100vh - 60px);
    top: 60px;
  }
}

/* Media query adjustments */
@media (max-width: 640px) {
  .toggleContainer {
    width: 100%;
    justify-content: center;
    margin: auto;
  }
  
    .productGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }
  .toggleButton {
    flex: 1;
    width: 50%;
  }
  .categoriesContainer{
    flex-direction: column;
  } 
  .tab1{
    width: 100%;
  }
  .categoriesList{
    margin-top: 1rem;
  }
}

@media (max-width: 640px) {
  .storeCategoryContainer {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    width: 70%;
  }

  .productCard {
    width: 100%;
  }

}

@media (min-width: 641px) {
 

  .productCard {
    min-width: 200px;
  }
}

.productsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  width: 100%;
  padding: 1rem;
}

.productCard {
  /* background-color: white; */
  border: 1px solid #e5e7eb;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.productImage {
  width: 100%;
  height: 7rem;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.productDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.productName {
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 500;
  color: black;
  margin: 0;
  text-align: left;
}

.viewMore {
  padding: 0.5rem 1.5rem;
  border: 1px solid #600AAD;
  border-radius: 20px;
  background: none;
  color: #600AAD;
  cursor: pointer;
  margin-top: auto;
  align-self: flex-end;
  white-space: nowrap;
}

.viewMore:hover {
  background-color: #600AAD;
  color: white;
}

.noProducts {
  grid-column: 1 / -1;
  text-align: center;
  padding: 2rem;
  font-family: 'Poppins';
  color: #6b7280;
}

@media (max-width: 768px) {
  .productsGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0.5rem;
    margin-top: 1rem;
  }

  .productCard {
    padding: 0.75rem;
  }

  .productImage {
    height: 5rem;
  }

  .productName {
    font-size: 0.9rem;
  }

  .viewMore {
    padding: 0.3rem 1rem;
    font-size: 0.9rem;
  }
}

