.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

.modalContent {
    background: white;
    width: 90%;
    max-width: 800px;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    overflow-y: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    color: #600AAD;
    font-size: 20px;
    font-weight: 500;

}

.closeButton {
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.storeInfo {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
}

.challanDetails {
    display: flex;
    gap: 25%;
    margin-top: 2rem;
}
span{
    color: black;
    font-size: 20px;
    font-weight: 500;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.downloadButton {
    background-color:#600AAD;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 15px;
    /* width: 10%; */
    margin-top: 20px;
    margin-left: 85%;
    cursor: pointer;
}

@media (max-width: 600px) {
    .modalContent {
        max-width: 340px;
    }

    .challanDetails ,.storeInfo {
        flex-direction: column;
        gap: 10px;
    }

    .table th, .table td {
        font-size: 12px;
    }

    .downloadButton {
        font-size: 14px;
        padding: 8px;
        margin-left: auto;
    }
}
