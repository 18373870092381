.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.trialImage {
  width: 200px;
  height: auto;
  margin-bottom: 1.5rem;
}

.message {
  font-size: 18px;
  color: #333;
  margin: 0;
}

@media (max-width: 480px) {
  .modalContent {
    width: 85%;
    padding: 1.5rem;
  }

  .trialImage {
    width: 150px;
  }

  .message {
    font-size: 16px;
  }
} 