/* Base styles for large screens */
.container {
  width: 100%;
  height: 100vh;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

/* Hide scrollbar for all major browsers */
.container {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

/* .cardContainer {
  margin: 0;
  display: grid; 
  gap: 1rem; 
  margin-top: 1rem;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  overflow-y: auto;
  position: relative;
  height: auto;
  max-height: 40rem;
  margin: 0 auto; 
  width: 100vw; 
} */

.cardContainer{
  display: grid;
    margin: 0;
    flex-direction: column;
    gap: 20px;
    width: 100vw;
    margin-top: 1rem;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    max-height: 42rem;
    position: relative;
    margin-bottom: 3rem;
    padding-bottom: 4rem;
}
/* Style for the cardBox */
.cardBox {
  display: flex;
  gap: 1rem;
  width: 100vw; /* Ensure cardBox takes full width of its container */
  overflow-x: auto;  /* Enable horizontal scrolling */
  padding: 1rem ;
  box-sizing: border-box;
}

/* Style for each box inside the cardBox */
.box {
  background-color: white;
  border: 2px solid rgb(217, 210, 210);
  padding: 1rem;
  text-align: center;
  border-radius: 8px;
  color: #600aad;
  flex: 0 0 18rem; /* Fix the width of each box */
  height: auto;
}
.box img {
  width: 100%; 
  height: 100%; 
}

.category {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: .5rem;
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  align-items: center;
  cursor: pointer;
  width: 100%; /* Set width to 100% of the container */
  max-width: 100%; /* Ensure it does not overflow the viewport */
  height: 3rem;
  overflow-x: hidden;
  position: relative; /* Keep position relative for flexibility */
}

.category img {
height: 1rem;
}
.category, .cardBox {
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.category::-webkit-scrollbar, .cardBox::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and WebKit browsers */
}

.img {
  height: 7rem;
  padding: 1rem;
  align-items: center;
  object-fit: cover;
}

.name {
  text-align: center;
  /* padding: 5px; */
  height: 2rem;
  align-items: center;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quantity p {
  color: gray;
}

.available p {
  color: gray;
}

.available {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  /* flex-direction: column; */
  color: gray;
  margin-left: 0;
  font-size: 20px;
}

.additem {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.additem img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
}

.additem input {
  width: 5rem;
  height: 2rem;
}

.button {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: white;
  /* position: fixed; */
  bottom: 0; /* Fix button at the bottom of the screen */
  left: 0;
  right: 0;
  padding: 1rem;
  /* z-index: 1000; */
  /* border: 1px solid black; */
}

.button button {
  background-color: #600aad;
  color: white;
  border-radius: 45px;
  width: 5rem;
  padding: .5rem;
}
.additem img{
  cursor: pointer;
  

}
.boxImg{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* Heading style */
h1 {
  font-weight: 500;
  font-size: 20px;
  color: #600aad;
}
.dateSelector{
  display: flex;
  /* width: 100%; */
  margin-top: .5rem;
  /* gap: 20px; */
}

/* For tablet screens (768px to 1024px) */
@media (max-width: 1024px) {
  .container {
    margin-right: 0;
    padding: 0.25rem;
    height: 87vh;
  }



  .name, .quantity {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .img {
    height: 6rem; /* Adjust image height */
  }
}

/* For mobile screens (max-width: 767px) */
@media (max-width: 767px) {
  .container {
    margin-right: 0;
    padding: 0.25rem;
    height: 87vh;
  }
  .cardContainer{
overflow-x: hidden;  }

  .available {
    font-size: 3px;
    align-items: self-start;
  }

  .img {
    height: 5rem;
  }

  .button {
    gap: 10px;
    margin-bottom: 3rem;
  }
  .cardContainer{
    width:23rem;
  }
  .cardBox{
    width: 23rem;
    /* padding: 0; */
  }
  .box{
    width: 30%;
   }
  .additem input {
    width: 3rem;
    height: 2rem;
  }
  .button {
  
    width: 100%;
    padding: 1rem;
    gap: 10px;
    background-color: white;
  }
  .category{
    overflow-x: hidden;
  }
  .button button {
    width: 45%;          
    margin: 0;
  }
  .cardImg{
    object-fit: cover;
    margin: 0;
    height: 100%;
  }
  .img{
    padding: 0;
    object-fit: cover;
  }
 
}
