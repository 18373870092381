/* By default, hide the container */
.container {
    display: none;
  }
  
  /* Bottom navigation styling for small screens */
  @media (max-width: 768px) {
    .container {
      display: flex; /* Show the container on screens 768px or smaller */
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color:white;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 3.5rem;
      padding: 0.8rem 0;
      /* border: 2px solid black; */
      box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.15); 

      z-index: 1000;
    }
  
    /* Style for each navigation item */
    .container div {
      text-align: center;
      font-size: 1rem;
      cursor: pointer;
      /* background: white; */
    }
    .dashbord{
        display:  flex ;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        /* border-radius: 42px; */
        padding: .5rem;
        width: 100%;
      }
      .dashbord1{
        display:  flex ;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: .5rem;
        width: 100%;
        
      }
      .create{
        margin-top: -1.5rem;
        font-size: 10px;
        z-index: 1000;
        position: absolute;
        width: 3.8rem;
        
        
        
    }
    .link{
      margin-top: 1.3rem;
      color: #600AAD;
font-size:1rem;
display: flex;
justify-content: center;
align-items: center;
flex-wrap:wrap;
gap: 10px;
    }
  }
  @media (max-width: 480px) {
    .link{
      margin-top: .8rem;
      color: #600AAD;
    }
    .create{
      margin-top: -2rem;
      font-size: 10px;
      z-index: 1000;
      position: absolute;
      width: 3.8rem;
      
      
      
  }
  }
  