.container {
  width: 100%;
  height: calc(100vh - 3.75rem );
  margin: 0;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  
}

/* Hide scrollbar for all major browsers */
.container , .cardContainer{
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;    /* Firefox */
}

.container::-webkit-scrollbar  , .cardContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.topContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 4rem;
  width: 100%;
}
.toggleContainer {
  width:35%;
  display: flex;
  margin: auto;
  margin-top: 1rem;
  margin-left: 1rem;
  /* margin-bottom: 16px; */
  background: #f3f4f6;
  padding: .5rem;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.toggleButton {
  padding: .5rem;
  border: none;
  border-radius: 40px;
  font-size: 14px;
  width: 50%;
  cursor: pointer;
  background: transparent;
  color: #6b7280;
  font-family: poppins;
font-size: 18px;

  transition: all 0.3s ease;
}

.activeToggle {
  background: #600AAD;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 40px;
  font-size: 14px;
  width: 50%;
  font-family: poppins;
font-size: 18px;
  
}
.searchContainer{
  width: 30%;
  height: auto;
}   
.searchContainer input{
  padding: .5rem;
  align-items: center;
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 500;
  color: black;

}

.selectContainer {
width: 95%;
justify-content: end;
display: flex;
align-items: center;
position: relative;
margin: 1rem 0;
}

.select{
width: 20%;
padding: 0.7rem 2.5rem 0.7rem 2.5rem;
border-radius: 40px;
 font-family: 'Poppins';
font-size: 1rem;
font-weight: 500;
border:none;
appearance: none;
background-color: white;
cursor: pointer;  
padding-left: 2.5rem;
background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
background-repeat: no-repeat;
background-position: right 1rem center;
background-size: 1em;
padding-right: 2.5rem;
}

.select:hover {
background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23600AAD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
}

.categoryIcon {
position: absolute;
left: 86.5%;
top: 50%;
transform: translateY(-50%);
width: 20px;
height: 20px;
margin-right: 0.5rem;
pointer-events: none;
}

.productsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  width: 100%;
  padding: 1rem;
  /* background: #eeeaea; */
  /* margin-top: 2rem; */
}

.productCard {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  background: white;

}

.productImage {
  width: 100%;
  height: 7rem;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}
.productCard h3 {
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 500;
  color: black;
  margin-right: 100%
}

.productImage {
width: 100%;
height: 10rem;
object-fit: cover;
margin-bottom: 1rem;
}
.productImageadd{
 
      width: 100%;
      height: 6rem;
      object-fit: cover;
      margin-bottom: 1rem;
    
}

.productName {
font-size: 16px;
font-weight: 500;
font-family: poppins;
color: black;
width: 100%;
}

.priceContainer {
display: flex;
align-items: center;
margin-top: 0.5rem;
/* white-space: nowrap; */
}

.originalPrice {
color: #6b7280;
text-decoration: line-through;
}

.discountedPrice {
color: #600AAD;
margin-left: 0.5rem;
}

.discount {
color: #600AAD;
font-size: 0.875rem;
margin-left: 0.5rem;
}

.ratingContainer {
display: flex;
align-items: center;
margin-top: 0.5rem;
}

.star {
color: #fbbf24;
}

.rating {
font-size: 0.875rem;
margin-left: 0.25rem;
}

.viewMore {
  padding: 0.5rem 1.5rem;
  border: 1px solid #600AAD;
  border-radius: 20px;
  background: none;
  color: #600AAD;
  cursor: pointer;
  margin-top: 1rem;
  margin-left: 50%;
  white-space: nowrap;
}

.viewMore:hover {
  background-color: #600AAD;
  color: white;
}


@media (max-width: 768px) {

  .container {
    
    margin-right: 0;
    margin-top: 0%;
    padding: 0rem;  
    height: 100%;
  }
 
.topContainer{

display: flex;
flex-direction: column;
height: auto;
}
.toggleContainer {
  width: 100%;
  justify-content: center;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.toggleButton {
  flex: 1;
}
.productsGrid {
  margin-top: 0rem;
  /* background:white; */
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  /* border: 1px solid black; */
  height: 100vh;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
}
.searchContainer{
  width: 100%;
  background: white;
  /* margin-top: 1rem; */
}
.viewMore{
  margin-left: 0;
}

.selectContainer {
  width: 100%;
  /* padding: 0 1rem; */
  /* border: 1px solid red; */
  margin-bottom: 0%;
}
.categoryIcon{
  display: none;
}
.select {
  border: 1px solid #575757;
  gap: 2rem;
  width: 50%;
}

.categoryIcon {
  left: 62%;
}
}
@media (max-width: 640px) {
.container {

    margin-right: 0;
    margin-top: 0%;
    padding: 0rem;  
    height: 100%;
    max-height: 37rem;
    background-color:0;
}
.cardContainer{

        max-height: 34rem; /* Set a height to make it scrollable */
        padding: 0rem;
        gap: 10px;
        overflow-y: auto; /* Enable vertical scrolling */
        margin-bottom: 2rem;
      
}


.productGrid {
grid-template-columns: 1fr;
padding: 0.5rem;
margin-bottom: 2rem;
}

.categoryItem {
width: 70px; /* Smaller on mobile */
}

.categoryImage {
/* width: 3rem;
height: 3rem; */
width: 100%;
}

.storeName {
font-size: 1.5rem;
margin-bottom: 0.5rem;
}
.storeContainer{
width: 100%;
}
.categoriesContainer{
margin-top: 0%;

}
.storeCategoryContainer{
display: flex;
width: 70%;
}
.storeHeader {
/* padding: 1rem 0; */
/* width: 30%; */
font-size: 10px;
overflow-wrap: break-word;

}

.productCard {
padding: 0.75rem;
gap:0;

}
.productCardadd{
width: 50%;
padding: 0.75rem;
}
.viewDetailsButtonadd{
padding: 0.25rem;
margin: auto;
font-size: 10px;
width: fit-content;
display: flex;
justify-content:flex-end;
align-items: self-end;
/* margin-left: auto; */
margin-top: .5rem;
}

.productImage {
height: 20rem;
border: 1px solid #315eb7;
}

.productName {
font-size: 0.8rem;
}
}

@media (min-width: 641px) and (max-width: 1024px) {
.container {
height: calc(100vh - 60px);
top: 60px;
}

.productGrid {
grid-template-columns: repeat(2, 1fr);
gap: 0.75rem;
}

.categoryItem {
width: 75px;
}
}

@media (min-width: 1025px) {
.productGrid {
grid-template-columns: repeat(4, 1fr);
max-width: 1200px;
margin: 0 auto;
}

.container {
height: calc(100vh - 60px);
top: 60px;
}
}

/* Media query adjustments */
@media (max-width: 640px) {
.toggleContainer {
width: 100%;
justify-content: center;
margin: auto;
padding: 0rem;
}
.searchContainer{
width: 100%;
background: white;
margin-top: 1rem;
} 

.productGrid {
grid-template-columns: repeat(2, 1fr);
gap: 0.75rem;
}
.toggleButton {
flex: 1;
max-width: 150px;
}
.categoriesContainer{
flex-direction: column;
} 
.tab1{
width: 100%;
}
.categoriesList{
margin-top: 1rem;
}
}

@media (max-width: 640px) {
.storeCategoryContainer {
display: flex;
gap: 0.5rem;
padding: 0.5rem;
width: 70%;
}

.productCard {
width: 100%;
}

}

@media (min-width: 641px) {


.productCard {
min-width: 200px;
}
}

.productsGrid {
display: grid;
grid-template-columns: repeat(4, 1fr);
gap: 1.5rem;
width: 100%;
padding: 1rem;
}

.productCard {
background-color: white;
border: 1px solid #e5e7eb;
box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
border-radius: 8px;
padding: 1rem;
text-align: center;
display: flex;
flex-direction: column;
}

.productImage {
width: 100%;
height: 7rem;
object-fit: cover;
border-radius: 8px;
margin-bottom: 1rem;
}

.productDetails {
flex: 1;
display: flex;
flex-direction: column;
}

.productName {
font-family: 'Poppins';
font-size: 1rem;
font-weight: 500;
color: black;
margin: 0;
text-align: left;
margin-bottom: 0.5rem;
}

.viewMore {
padding: 0.5rem 1.5rem;
border: 1px solid #600AAD;
border-radius: 20px;
background: none;
color: #600AAD;
cursor: pointer;
margin-top: auto;
align-self: flex-end;
white-space: nowrap;
}

.viewMore:hover {
background-color: #600AAD;
color: white;
}

.noProducts {
grid-column: 1 / -1;
text-align: center;
padding: 2rem;
font-family: 'Poppins';
color: #6b7280;
}

@media (max-width: 768px) {
.productsGrid {
grid-template-columns: repeat(2, 1fr);
gap: 1rem;
padding: 0.5rem;
}

.productCard {
padding: 0.75rem;
}

.productImage {
height: 10rem;
}

.productName {
font-size: 0.9rem;
}

.viewMore {
padding: 0.3rem 1rem;
font-size: 0.9rem;
}
}

