.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal content */
  .modalContent {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    max-width: 500px;
    width: 30%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.modalContent2 {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  max-width: 500px;
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
 margin: auto;
}
.img{
    width: 3rem;
}
.text{
    color: #0F5132;
    align-items: center;
    text-align: center;
      font-size: 22px;
}
.text1{
    color: #EB3838;
    align-items: center;
    text-align: center;
      font-size: 22px;
}
  .modalClose {
   
    border: 1px solid #0F5132;
    background: #0F5132;
    color: white;
    border-radius: 42px;
    padding: .5rem;
    width: 5rem;
    cursor: pointer;
  }
  .modalClose1 {
   
    border: 1px solid #EB3838;
    background: #EB3838;
    color: white;
    border-radius: 42px;
    padding: .5rem;
    width: 5rem;
    cursor: pointer;
  }
  .save{
    background: #600AAD;
    color: white;
    border-radius: 42px;
    padding: .5rem;
    width: 5rem;
    cursor: pointer;
  }
  .saveCancel{
    display: flex;
    justify-content: right;
    gap: 10px;
  }
  .close{
    border: 1px solid #600AAD;
    background:white;
    color:#600AAD;
    border-radius: 42px;
    padding: .5rem;
    width: 5rem;
    cursor: pointer;
  }
  .complete{
    border: 1px solid #600AAD;
    background:white;
    color:#600AAD;
    border-radius: 42px;
    padding: .5rem;
    width: 10rem;
    cursor: pointer;
  }

  .button{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
  }
  .item{
    display: flex;
    justify-content: left;
    width: 100%;
    /* margin-top: 1.5rem; */
    gap: 5px;
  }
  .item label{
    color: #600AAD;

  }
  .items{
    display: flex;
    justify-content: left;
    flex-direction: column;
    width: 100%;
    /* margin-top: 1.5rem; */
    gap: 5px;
  }
  .items label{
    color: #600AAD;

  }
  .head{
    width: 100%;
    color: #600AAD;
    justify-content: start;
    font-size: 20px;
  }
  select{
    border: 1px solid rgb(194, 189, 189);
    height: 2.5rem;
  }
 
  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between boxes */
    justify-content: space-between;
    border: 1px solid black;
}

.container1 {
  display: flex;
  flex-wrap: wrap;
  height: 400px; /* Set a fixed height or use max-height */
  margin: auto;
  overflow-y: auto; /* Enable vertical scroll */
  gap: 20px; /* Space between boxes */
  justify-content: space-between;
  position: relative;
}


.box {
    box-shadow: 0px 20px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    color: rgb(61, 94, 155);
    transition: transform 0.3s;
    flex: 1 1 calc(50% - 20px); /* Adjusts each box to take up roughly half the row with a gap */
    max-width: calc(100% - 20px);
    box-sizing: border-box;
    gap: 5px;
    height: 18rem;
    /* border: 1px solid black; */
}

  .addDamageQuantity{
    display: flex;
  }
  .image{
height: 6rem;
width: 90%;
/* margin: auto; */
object-fit: cover;
/* border: 1px solid black; */
  }
  .price{
    color: black;
    font-size: 20px;
    margin-left: 50%;
  }
  .dropdown {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdownSelected {
    font-size: 16px;
  }
  
  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 1000;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .dropdownItem {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .dropdownItem:hover {
    background: #f0f0f0;
  }
  
  .dropdownIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
 
  @media (max-width: 480px) {
    .modalContent {
        width: 90%; /* Full width for small screens */
        padding: 1rem;
    }
    .modalContent2 {
      width: 90%; /* Full width for small screens */
      padding: 1rem;
  }

    .text {
        font-size: 18px; /* Smaller font size for mobile */
    }

    .modalClose {
        width: 100%; /* Button takes full width */
        padding: 0.75rem;
    }
    .container1 {
      height: 200px; /* Further reduce height for mobile */
      flex-direction: column; /* Stack items vertically if needed */
      gap: 10px; /* Further reduce space between boxes */
    }
}

/* Media Queries for Tablet Devices (max-width: 768px) */
@media (max-width: 768px) {
  .modalContent {
    width: 90%;
    max-width: 90%;
    padding: 1rem;
  }
  .container1 {
    height: 300px; /* Reduce height for tablets */
    gap: 15px; /* Reduce space between boxes */
  }
    .text {
        font-size: 20px; /* Slightly smaller font for tablets */
    }

    .modalClose {
        width: 7rem; /* Adjust button width for tablets */
        padding: 0.5rem;
    }
    select{
width: 8rem;
    }
}



