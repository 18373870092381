/* Hide scrollbar for all major browsers */
.container , .cardContainer , .categoriesContainer , .categoriesList , .storeContainer , .storeCategoryContainer{
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;    /* Firefox */
}

.container::-webkit-scrollbar  , .cardContainer::-webkit-scrollbar , .categoriesContainer::-webkit-scrollbar , .categoriesList::-webkit-scrollbar , .storeContainer::-webkit-scrollbar , .storeCategoryContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.container {
width: 100%;
height: calc(100vh - 3.75rem );
min-height: 100vh;
display: flex;
flex-direction: column;
border-radius: 0.5rem;
overflow-y: auto;
overflow-x: hidden;
padding: 2rem;
margin: 0;
margin-bottom: 2rem;
margin-bottom: 2rem;
position: relative;
}

.cardContainer {
display: flex;
flex-direction: column;
gap: 2rem;
width: 100%;
height: auto;
margin-bottom: 2rem;
flex: 1;
overflow-y: auto;
padding-bottom: 2rem;
margin-bottom: 2rem;
}

.header {
background-color: #600AAD;
padding: 1rem;
display: flex;
align-items: center;
}

.backButton {
color: white;
display: flex;
align-items: center;
gap: 0.5rem;
border: none;
background: none;
cursor: pointer;
font-size: 1rem;
}



.shopInfo {
/* margin-bottom: 2rem; */
width: 100%;
display: flex;
gap: 1rem;
padding: 1rem;
align-items: center;
background-color: white;
/* margin-top: -1rem; */
}

.shopProfile {
display: flex;
align-items: center;
gap: 1rem;
width: 10%;
margin-bottom: 0;
}

.shopIcon {
width: 6rem;
height: 6rem;
border-radius: 50%;
object-fit: cover;
margin: auto;
}

.shopDetails {
margin: 0;
width: 50%;
padding: 0;
margin-top: .5rem;
gap: 1rem;
}
.shopDetails h2 {
font-size: 1.5rem;
font-weight: 600;
font-family: 'Poppins';
}
.showMobileButton {
padding: 0.5rem;
border: 2px solid #600AAD;
border-radius: 20px;
background: none;
color: #600AAD;
cursor: pointer;
margin-left: auto;
font-family: 'Poppins';
font-size: .8rem;
font-weight: 500;
}
.showMobileButton:hover {
background-color: #600AAD;
color: white;
}
.shopDetails span {
font-family: 'Poppins';
font-size: 1rem;
font-weight: 500;
color: black;
margin-left: 1rem;
}

.toggleContainer {
  width:60%;
  display: flex;
  margin: auto;
  /* margin-top: 1rem; */
  margin-left: 1rem;
  /* margin-bottom: 16px; */
  background-color: #f3f4f6;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}


.toggleButton {
  padding: .5rem;
  border: none;
  border-radius: 40px;
  font-size: 14px;
  width: 50%;
  cursor: pointer;
  background: transparent;
  color: #6b7280;
  font-family: poppins;
font-size: 18px;

  transition: all 0.3s ease;
}

.activeToggle {
  background: #600AAD;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 40px;
  font-size: 14px;
  width: 50%;
  font-family: poppins;
font-size: 18px;
  
}



.productsGrid {
display: grid;
grid-template-columns: repeat(4, 1fr);
gap: 1.5rem;
width: 100%;
/* margin-top: 2rem; */
/* padding: 1rem; */
}

.productCard {
border: 1px solid #e5e7eb;
border-radius: 8px;
padding: 1rem;
white-space: nowrap;
text-align: center;
background-color: white;
}

.productImage {
width: 100%;
height: 7rem;
object-fit: cover;
border-radius: 8px;
margin-bottom: 1rem;
}
.productCard h3 {
font-family: 'Poppins';
font-size: 1.2rem;
font-weight: 500;
color: black;
margin-right: 100%
}

.viewMore {
padding: 0.5rem 1.5rem;
border: 1px solid #600AAD;
border-radius: 20px;
background: none;
color: #600AAD;
cursor: pointer;
margin-top: 1rem;
margin-left: 50%;
white-space: nowrap;
}

.viewMore:hover {
background-color: #600AAD;
color: white;
}

.searchContainer{
  width: 40%;
  height: auto;
}   
.searchContainer input{
  padding: .5rem;
  align-items: center;
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 500;
  color: black;

}
.shopDetail span{
font-size: 1.2rem;
}
.shopDetail {
  margin-top: 1rem;
}

@media (max-width: 768px) {
.productsGrid {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: .5rem;
  padding: 0rem;
}

 .container {
    
  margin-right: 0;
  margin-top: 0%;
  padding: 0rem;  
  height: 100%;
}
.cardContainer{
      max-height: 33rem; /* Set a height to make it scrollable */
      padding: 0rem;
      gap: 10px;
      overflow-y: auto; /* Enable vertical scrolling */
      margin-bottom: 2rem;
    
}
.productImage {
  height:4rem;
}

.shopInfo {
  background-color: #e5e7eb;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  gap: 0;
}

.shopProfile {
  margin-bottom: 0;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: -3rem;
  margin-top: 1rem;
  z-index: 1000;
  position: relative;
}

.shopDetails {
  padding: 1rem;
  background-color: white;
  width: 100%;
}

.shopDetails h2 {
  margin-top: 2rem;
  align-items: center;
  text-align: center;
}

.showMobileButton {
  width: 50%;
  margin: auto;
  white-space: nowrap;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.shopDetail {
  display: flex;
  justify-content: center;
  margin-top: 0;
}

.shopDetail span {
  font-size: 1.2rem;
  margin-right: 0;
  width: 50%;
  white-space: nowrap;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.toggleContainer {
  width: 100%;
  justify-content: center;
  margin: auto;
  margin-top: 1rem;
}

.toggleButton {
  flex: 1;
}

.searchContainer {
  width: 100%;
  padding: 1rem;
  margin-top: 0;
  background: white;
}

.viewMore {
  margin-left: 0;
  padding: .2rem;
}
}

