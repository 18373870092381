.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  background: white;
  padding: 1rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}
.modalContainer {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .modalContainer::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

.closeButton {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.modalTitle {
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0;
}

.featureIcon {
  width: 20px;
  display: flex;
  justify-content: center;
}

.featureIcon.check {
  color: #4CAF50;
}

.featureIcon.cross {
  color: #F44336;
}

.featureText {
  flex: 1;
  color: #333;
}

.featureDetail {
  color: #666;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
}
.checkmark{
  margin-right: 3rem;
  border: 1px solid green;
  width: 2rem;
  border-radius: 50%;
  color: green;
  display: flex;
  justify-content: center;

}


.buyButton {
  color: #600aad;
  border: 1px solid #600aad;
  background: white;
  /* border: none; */
  padding: 0.5rem;
  border-radius: 25px;
  font-weight: 500;
  line-height: auto;
  letter-spacing: 0%;
  font-size: 25px;
  margin-top: 1.5rem;
  cursor: pointer;
  font-style: poppins;
  width: 100%;
  transition: background-color 0.3s ease;
}

.buyButton:hover {
  background: #4c0887;
  color :white
}
