/* Default Styles for Larger Screens */
.container {
    width: 100%;
    height: 100vh;
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  
  /* Hide scrollbar for all major browsers */
  .container {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }
  
  .container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  
  .topTab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  
  .back {
    display: flex;
    align-items: center;
    background: #600aad;
    color: white;
    border-radius: 50px;
    padding: 0.5rem;
    gap: 5px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
  }
  
  .topTab p {
    color: #666666;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    margin-top: 0.2rem;
  }
  
  .cardContainer {
    margin: 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: 8px;
    padding: 2rem;
    margin-top: 1rem;
    padding-bottom: 2rem;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    height: auto;
    position: relative;
    transition: height 0.3s ease-in-out;
  }

  .head {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .head p {
    color: #600aad;
    font-weight: 500;
    font-size: 30px;
    margin-top: -1rem;

  }

  .date{
display: flex;
gap: 10px;
width: 80%;
  }
  
  label {
    color: black;
    font-size: 16px;
    font-weight: 500;
  }

  .additem {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 1rem;
  }
  
  .additem button {
    color: white;
    background: #600aad;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    border-radius: 50px;
    width: 10rem;
  }

  .startDate{
    border: 3px solid #600aad;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .endDate{
    border: 3px solid #600aad;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center
  }
  .date{
    /* border: 1px solid black; */
    gap: 20px;
    width: 20.8rem;
    margin-left: 14rem;
    margin-bottom: 1rem;

  }
  
  .party{
    height: auto;
    z-index: 1000;
  }
 
  .expanded {
    height: 600px; 
    margin: 0%;
  }
  
  .select{
    padding: 1rem;
    width:100%;
    border: 1px solid #600aad;
    border-radius: 45px;
margin-top: 1rem;
  }
  .select h1{
    text-align: center;
  }
 /* Ensure table container has a fixed height for scrolling */
.table {
  width: 100%;
  height: 15rem; /* Adjust this height as needed */
  overflow-y: auto;
  border-collapse: collapse;
  /* padding: 1rem; */
  margin-bottom: 1rem;
  position: relative; 
}

.table {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.table::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.table thead {
  position: sticky;
  top: 0; 
  background-color: white; 
}

/* Table header cells */
.table th {
  background-color: white;
  color: #600aad;
  padding: 10px;
  border-bottom: 3px solid #600aad;
}

/* Table body cells */
.table td {
  text-align: start;
  align-items: start;
  padding: .5rem;
  border: none; 
  background: rgb(249, 246, 246);
}

/* Optional: Add a hover effect to the rows */
.table tbody tr:hover {
  background-color: #f1f1f1; /* Light gray background on hover */
}


/* Media Queries for small screens */
@media only screen and (max-width: 600px) {
  .container {
    margin-right: 0;
    padding: 0.25rem;
    height: 87vh;
  }

  .dateBox {
width: 100%; 
margin-top: 1rem;
}
.date{
  margin-left: 0;
  width: 100%;
}
.range{
  width: fit-content;
  margin-left:-15rem;
}

  .additem button {
    width: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .table {
    height: 12rem;
  }
}

