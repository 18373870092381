

  /* Category container styles */
  .inventoryDetail-container{
    width: 70rem;
    height: calc(100vh - 70px);
    padding: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
  }
  
  /* Hide scrollbar for all major browsers */
  .inventoryDetail-container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  .inventoryDetail-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }


.main-edit{
    gap: 10px;
    width: 100%;
    /* margin-top: 3rem; */
    display: flex;
    /* overflow-y: auto; */
    /* border: 1px solid black; */
    gap: 10px;
    width: 100%;
    height: 87vh;
    display: flex;
    overflow-y: auto;
    border-radius: 45px;

}
.right-edit {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  .right-edit::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }


.left-edit{
width: 40%;
margin-top: 10rem;
gap:10px;

}
.left-edit input{
    width: 50%;
    margin-left: 8rem;
}
.left-edit img{
    width: 20rem;
    margin: auto;
}
.right-edit{
    width: 60%;
    /* height: 100%; */
    /* border: 1px solid black; */
    overflow-y: auto;
    /* margin-top: 2rem; */
}
.card-edit{
    /* margin: 2rem; */
   border-radius: 8px;
   box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
   padding: 1rem;
   display: flex;
   flex-direction: column;
   /* border: 1px solid black; */
}
.head-edit{
    display: flex;
    justify-content: space-between;
align-items: center;
text-align: center;
}
.head-edit p{
    font-size: 24px;
    font-weight: 600;
    color: #600AAD;

}
.save-cancel{
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 1rem;
    gap: 10px;

}
.save{
background: #600AAD;
color: white;
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid #600AAD;
border-radius: 50px;
padding: .5rem;
    
}

.info-edit{
    display: flex;
    flex-direction: column;
    justify-content:left;
    width: 100%;
    
}
.description {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.description textarea {
    border: 2px solid #600AAD;
    width: 100%;
    /* padding: 0.5rem; */
    margin-top: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 20px;
text-align: left;
color: #999999;
}
.item,.avail-unit,.edit-price,.category-godown{
    display: flex;
    justify-content:left;
    width: 100%;
    margin-top:1.5rem ;
    gap: 5px;
}
.avail-unit{
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.item input{
    background:  #DEE2E6;

}
input{
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    align-items: center;
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 20px;
text-align: left;

}
label{
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 20px;
text-align: left;

}

.form-group{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
    margin-top: .5rem;

}

.form-group select{
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
line-height: 20px;
text-align: left;
}

.edit-godown{
    margin-top: 1rem;
}

.addCat{
    border: 1px solid #600AAD;
    margin-top: 1rem;
    border-radius: 45px;
    background: #600AAD;
    padding: auto;
    width: 10rem;
    height: 2rem;
    align-items: center;
    margin: auto;
}
.form-group button{
    color: white;
    margin-left: 0%;

}
.table {
    width: 100%;
    height: auto; /* Adjust this height as needed */
    overflow-y: auto;
    border-collapse: collapse;
    /* padding: 1rem; */
    margin-bottom: 1rem;
    position: relative; 
  }
  
  .table {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  .table::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
  
  .table thead {
    position: sticky;
    top: 0; 
    background-color: white; 
  }
  
  /* Table header cells */
  .table th {
    background-color: white;
    color: #600aad;
    padding: 10px;
    border-bottom: 3px solid #600aad;
}

/* Table body cells */
.table td {
    margin: auto;
    text-align: start;
    align-items: start;
    padding: .5rem;
    border: none;
    background: rgb(249, 246, 246);
  }
  
  /* Optional: Add a hover effect to the rows */
  .table tbody tr:hover {
    background-color: #f1f1f1; /* Light gray background on hover */
  }
  
  .add{
    display: flex;
justify-content: right;
}
.addGodown{
    border: 1px solid #600AAD;
    margin-top: 1rem;
    border-radius: 45px;
    color: white;
    background: #600AAD;
     padding: .8rem;
}
.edit-unit{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: center;
    /* padding: .5rem; */
    
}   
.edit-unit select{
    background: white !important;
    width: 100%;
    border-radius: 4px;
    /* padding: .5rem; */
    font-size: 16px !important;
    padding: 0.5rem;
   
    text-align: center;
    align-items: center;
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 20px;

}
.edit{
    width: 6rem;
}
.delete{
width: 7rem;
}
.item-title{
    white-space: nowrap;
}
@media (max-width: 767px) {
    .inventoryDetail-container {
        margin-right: 0;
        padding: 0rem;
        height: 80vh;
        margin-bottom: 2rem;
      }
      .main-edit{
        height: 88vh;
        margin-bottom: 2rem;
padding: 0;
      }
      .main {
        flex-direction: column;
        align-items: center;
        height: 100vh;
        margin-bottom: 2rem;

    }
.edit-unit select{
    width: 9rem;
}
  .right-edit {
      width: 100%;
      margin: 0;
     
      text-align: center;
      overflow-y: auto; 
      max-height: 100vh;
  }

  .left-edit {
      display: none;
  }
  .right-edit{
    padding: 0;
    margin: 0;
    /* width: 100vw; */
    /* border: 1px solid black; */
  }

  .card-edit {
      margin: 1rem;
      padding: 0rem;
      box-shadow: none;
  }

  .head-edit {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .head-edit p {
      font-size: 28px;
      margin-top: -1rem;
      width: 100%;
  }

  .save-cancel {
      width: 100%;
      /* justify-content: space-between; */
  }
.edit{
    width: 50%;
}
.delete{
    width: 50%;
}
  .save {
      padding: 0.5rem 1rem;
  }

  .info-edit {
      width: 100%; 
      margin: 0; 
      padding: 0; 
      box-sizing: border-box; 
      margin-top: .5rem;
  }

  .item,
  .avail-unit,
  .edit-price,
  .category-godown {
      display: flex;
      width: 100%;
      margin-top: 0rem;
      justify-content: center;
      gap: 5px;
  }

  .description {
      margin-top: 0.5rem;
  }

  .edit-price div {
      width: 33%;
      align-items: center;
  }

  .in {
      margin-top: 1rem;
  }

  .info-edit > div {
      margin-bottom: 1rem; /* Add bottom margin to each form group */
  }
}

  